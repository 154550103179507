/*google fonts*/
/* @import url('https://fonts.googleapis.com/css?family=PT+Sans'); */
/* @import url('https://fonts.googleapis.com/css?family=Alegreya+Sans:400,400i,500,500i,700,700i,800,800i,900,900i|Open+Sans:400,400i,600,600i,700,700i,800,800i'); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/*
 *
 *   Thememinster - Responsive CRM admin panel
 *   version 1.0
 *
 */
/*------------------------------------------------------------------------------
[Master Stylesheet]

Project:       Thememinster - Responsive CRM admin panel
Version:        1.0
Last change:    04/29/2017
Primary use:   Responsive CRM admin panel
Author:         Thememinster
URL:            https://themeforest.net/user/Thememinster
------------------------------------------------------------------------------*/
/*
This file contains the common info of this theme.
*/
/*------------------------------------------------------------------------------
[Table of contents]
    * Header & Navigation sidebar
        * Dashboard
            *Notifications,profile,inbox,search box.
            *Boxed view cards view
            *Charts Bar,line etc
            *Calender
            *google maps
            *Data table
        * Layout
            *wrapper 
            *layout-boxed
            *Fixed layout
        * Content
            *Content
            *Work Experience
        * selection
        * H1 - H6 Heading
        * General Links
        * margins & paddings
        * color
        * border color
        * background color
        * Page Header
        * Main Header Component: 
             * Content 
             * main-header
             * header-title
             * content-header
             * navbar-nav
             * Sidebar
             * Sidebar Mini
             * search box
             * Dropdown menus
        * Dashboard Component:
             * cards
             * upcoming works
             * Calender
             * Google map
        * UI Elements:     
             * Panel page
             * Buttons page
             * Tabs page
             * Notifications page
             * Tree View
             * progressbars page
             * List view
             * Typography page
             * Modals page
             * iCheck, Toggle page
             * Label, Badges, Alearts page
        * Other page component:
             * Lockscreen page
             * Login page
             * 404 page
             * 505 page
        * chart widget:
             * Pie chart widget
             * flot chart
             * Sparklines chart
        * Calender page
             * dropzone
        * Icon pages
             * pe icon
             * Flag icon pages
             * Themify icons pages
             * Social icon pages
             * Material-icon-inner
        * Mailbox details
        * Table
        * Component: Table:
            * Form page
            * Form wizard
        * preloader
        * custom scrollbar
        * back to top 
        * Portfolio page
        * customer component: 
             * custom button
             * Notes
             * buttonlist
             * custom button
       ------------------------------------------------------------------------------*/
/*
# [Color codes]
# base color code:#405671
# light teal: #405671
# Black (sidebar) #222d32
----------------------------------------------------------------------------- */
/* General Styling & Typography
------------------------------------------------------------------------------*/
/*Core: General Layout Style
==============================================================================*/

html,
.layout-boxed html,
.layout-boxed body {
  height: 100%;
}
body {
  overflow-x: hidden;
  overflow-y: auto;
  /* color: #374767; */
  background-color: #f1f3f6;
  font-family: "Montserrat", sans-serif !important;
  min-height: 100%;
}
/*--- Layout ---*/

.wrapper {
  min-height: 100%;
  position: relative;
  overflow: hidden;
}
.wrapper:before,
.wrapper:after {
  content: " ";
  display: table;
}
.wrapper:after {
  clear: both;
}
.layout-boxed .wrapper {
  max-width: 1250px;
  margin: 0 auto;
  min-height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  position: relative;
}
/* .layout-boxed {
    background: url('../img/boxed-bg.jpg') repeat fixed;
} */
.content-wrapper,
.right-side,
.main-footer {
  -webkit-transition: -webkit-transform 0.3s ease-in-out,
    margin 0.3s ease-in-out;
  -webkit-transition: margin 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
  transition: margin 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
  /* margin-left: 250px; */
  margin: auto;
  z-index: 820;
}
.layout-top-nav .content-wrapper,
.layout-top-nav .right-side,
.layout-top-nav .main-footer {
  margin-left: 0;
}
.border-drop {
  box-shadow: 0px 0px 10px 0px#ddd !important ;
}
@media (max-width: 767px) {
  .content-wrapper,
  .right-side,
  .main-footer {
    margin-left: 0 !important;
  }
}
@media (min-width: 768px) {
  .sidebar-collapse .content-wrapper,
  .sidebar-collapse .right-side,
  .sidebar-collapse .main-footer {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .sidebar-open .content-wrapper,
  .sidebar-open .right-side,
  .sidebar-open .main-footer {
    -webkit-transform: translate(250px, 0);
    transform: translate(250px, 0);
  }
}
.content-wrapper,
.right-side {
  min-height: 90%;
  z-index: 800;
  background-color: #eeeeee;
}
.main-footer {
  background: #dedede;
  padding: 15px;
  color: #444;
  border-top: 1px solid #d2d6de;
  text-align: center;
}
/*--- Fixed layout ---*/

.fixed .main-header,
.fixed .main-sidebar,
.fixed .left-side {
  position: fixed;
}
.fixed .main-header {
  top: 0;
  right: 0;
  left: 0;
}
.fixed .content-wrapper,
.fixed .right-side {
  padding-top: 60px;
}
@media (max-width: 767px) {
  .fixed .content-wrapper,
  .fixed .right-side {
    padding-top: 120px;
  }
}
.fixed.layout-boxed .wrapper {
  max-width: 100%;
}
body.hold-transition .content-wrapper,
body.hold-transition .right-side,
body.hold-transition .main-footer,
body.hold-transition .main-sidebar,
body.hold-transition .left-side,
body.hold-transition .main-header .navbar,
body.hold-transition .main-header .logo {
  /* Fix for IE */

  -webkit-transition: none;
  transition: none;
}
/*---media query for i con button*/

/*--- Content ---*/

.content {
  min-height: 250px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  background-color: "#EEEEEE";
}
@media (max-width: 767px) {
  .content {
    padding: 0 15px 10px;
  }
}
/*--lead list button media query here---*/

/*--- Selection ---*/

::-moz-selection {
  color: #fff;
  background: #405671;
  text-shadow: none;
}
::selection {
  color: #fff;
  background: #405671;
  text-shadow: none;
}
/* H1 - H6 font */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Montserrat", sans-serif;
  margin-top: 0;
  margin-bottom: 0;
}
p {
  margin: 0 0 10px;
  font-family: "Montserrat", sans-serif;
}
/* General Links */

a {
  color: #405671;
  text-decoration: none;
}
a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
  color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

hr {
  border-top: 1px solid #e1e6ef;
}
/* margins & paddings */

.m-0 {
  margin: 0;
}
.m-t-0 {
  margin-top: 0px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-r-2 {
  margin-right: 2px;
}
.m-r-5 {
  margin-right: 5px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-b {
  margin-bottom: 20px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.p-0 {
  padding: 0;
}
.p-l-30 {
  padding-left: 30px;
}
.p-r-30 {
  padding-right: 30px;
}
.p-20 {
  padding: 20px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.space-25 {
  margin: 12.5px 0;
  line-height: 0;
}
/*color*/

.color-green {
  color: #50ab20;
}
.color-red {
  color: #e5343d;
}
.color-red {
  color: #e5343d;
}
.color-violet {
  color: #8e23e0;
}
.color-gray {
  color: #9a9a9a;
}
.color-yellow {
  color: #ffb61e;
}
.color-info {
  color: #62d0f1;
}
/*text color*/

.text-white {
  color: #fff;
}
.text-muted {
  color: #9a9a9a;
}
.text-primary {
  color: #428bca;
}
.text-success {
  color: #50ab20;
}
.text-info {
  color: #62d0f1;
}
.text-warning {
  color: #ffb61e;
}
.text-danger {
  color: #e5343d;
}
.text-inverse {
  color: #3b3e47;
}
.text-purple {
  color: #5b69bc;
}
.text-pink {
  color: #ff8acc;
}
.text-black {
  color: #000;
}
.text-violet {
  color: #8e23e0;
}
/*border color*/

.border-all {
  border: 1px solid #e4e5e7;
}
.border-btm {
  border-bottom: 1px solid #e4e5e7;
}
.border-green {
  border-color: #50ab20;
}
.border-red {
  border-color: #e5343d;
}
.border-violet {
  border-color: #8e23e0;
}
.border-gray {
  border-color: #9a9a9a;
}
.border-yellow {
  border-color: #ffb61e;
}
.bg-custom {
  background: #405671;
  border-radius: 3px;
}
.font_customm h4 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.bg-custom .fa {
  color: #fff;
  padding: 5px;
}
/*background color*/

.bg-red {
  background: #e5343d;
}
.bg-violet {
  background: #8e23e0;
}
.bg-gray {
  background: #9a9a9a;
}
.bg-yellow {
  background: #ffb61e;
}
.bg-green {
  background: #50ab20;
}
/*--- Page Header ---*/

.page-header {
  margin: 10px 0 20px 0;
  font-size: 22px;
}
.page-header > small {
  color: #666;
  display: block;
  margin-top: 5px;
}
/*--- dashboard ---*/
.box-shadow-new {
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
}
.metrics-new {
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  width: 100%;
  margin: 0 0 10px 0;
  padding: 0;
  background: white;
  border-radius: 12px;
  color: #193359;
}
.metrics-new__item {
  width: 25%;
  padding: 1em 0;
  border-left: 1px solid #ededed;
  text-align: center;
}
.metrics-new__item:first-child {
  border: none;
}
.metrics-new__table {
  padding-left: 1em;
  padding-right: 1em;
}
.metrics-new .metrics-new__title {
  border-bottom: 1px solid #d8e0e2;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.metrics-new__table td {
  padding-left: 1rem;
  padding-right: 1rem;
}
.text-left {
  text-align: left;
}
.text-small {
  font-size: 12px;
}
/*--- Component: Main Header ---*/

.main-header {
  position: relative;
  max-height: 120px;
}
.main-header .navbar {
  -webkit-transition: margin-left 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out;
  margin-bottom: 0;
  margin-left: 0px;
  border: none;
  min-height: 60px;
  border-radius: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #ffffff;
}
.layout-top-nav .main-header .navbar {
  margin-left: 0;
}
.main-header #navbar-search-input.form-control {
  background: rgba(255, 255, 255, 0.2);
  border-color: transparent;
}
.main-header #navbar-search-input.form-control:focus,
.main-header #navbar-search-input.form-control:active {
  border-color: rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.9);
}
.main-header #navbar-search-input.form-control::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}
.main-header #navbar-search-input.form-control:-ms-input-placeholder {
  color: #ccc;
}
.main-header #navbar-search-input.form-control::-webkit-input-placeholder {
  color: #ccc;
}
.main-header .navbar-custom-menu,
.main-header .navbar-right {
  float: right;
  margin-right: 9px;
  margin-bottom: -1px;
}
.icon_fontweasome p {
  font-size: 14px;
}
.nav-item.dropdown.dropdown-user img {
  width: 45px;
  height: 45px;
}
@media (max-width: 991px) {
  .main-header .navbar-custom-menu a,
  .main-header .navbar-right a {
    color: inherit;
  }
  .navbar-custom-menu .navbar-nav > li > a {
    padding-top: 7px;
  }
  .custom_pills4 .nav-pills .nav-link,
  .nav-pills .show > .nav-link {
    margin-top: 6px !important;
  }
  .sideways a {
    margin-left: 8px !important;
  }
  .sideways2 a {
    margin-right: -37px !important;
  }
}
@media (max-width: 767px) {
  .main-header .navbar-right {
    float: none;
  }
  .navbar-collapse .main-header .navbar-right {
    margin: 7.5px -15px;
  }
  .main-header .navbar-right > li {
    color: inherit;
    border: 0;
  }
  .main-header .navbar-custom-menu,
  .main-header .navbar-right {
    float: right;
    margin-right: 10px;
  }
}
.main-header .sidebar-toggle {
  float: left;
  background-image: none;
  padding: 15px 14px;
  font-size: 26px;
  line-height: 26px;
  margin-right: 7px;
}
.main-header .sidebar-toggle .icon-bar {
  display: none;
}
a.sidebar-toggle .pe-7s-angle-left-circle:hover {
  color: #405671;
  font-weight: 500;
  font-size: 28px;
}
a.sidebar-toggle .pe-7s-angle-left-circle {
  font-weight: 500;
  font-size: 28px;
}
.main-header .navbar .nav > li.user > a > .fa,
.main-header .navbar .nav > li.user > a > .glyphicon,
.main-header .navbar .nav > li.user > a > .ion {
  margin-right: 5px;
}
.main-header .navbar .navbar-custom-menu > ul > li > a > .label {
  position: absolute;
  top: 6px;
  right: 7px;
  border-radius: 50em;
  height: 18px;
  width: 18px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
}
.main-header .logo {
  display: block;
  float: left;
  height: 60px;
  font-size: 20px;
  line-height: 60px;
  /* width: 250px; */
  padding: 0 50px 0px 0px;
  position: relative;
  z-index: 811;
  font-weight: 300;
  overflow: hidden;
  color: #241d1d;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
.main-header .logo .logo-lg {
  display: block;
}
.main-header .logo .logo-lg img,
.main-header .logo .logo-mini img {
  height: 39px;
}
.main-header .logo .logo-mini {
  display: none;
}
.main-header .navbar-brand {
  color: #fff;
}
.content-header {
  position: relative;
  padding: 12px 30px;
  background-color: #eeeeee;
  border-bottom: 1px solid #e1e6ef;
  margin-bottom: 0px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 5px;
}
.content-header hr {
  border-top: 1px solid #ddd;
}
.content-header .header-icon {
  font-size: 48px;
  color: #405671;
  width: 68px;
  float: left;
  margin-top: -4px;
  line-height: 0;
}
.content-header .header-title {
  margin-left: 68px;
}
.content-header .header-title h1 {
  font-size: 24px;
  color: #222;
  font-weight: 600;
  margin-bottom: -5px;
}
.content-header .header-title small {
  font-size: 13px;
  display: inline-block;
  padding-left: 4px;
  font-weight: 600;
  color: #222;
}
.header-title .breadcrumb {
  float: right;
  background: #fff;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  padding: 7px;
  position: absolute;
  bottom: 18px;
  right: 30px;
  border-radius: 0;
  border: 1px solid #e1e6ef;
  font-weight: 600;
}
.header-title .breadcrumb > li > a {
  color: #374767;
  text-decoration: none;
  display: inline-block;
}
.breadcrumb > .active {
  color: #3c990b;
  font-weight: 700;
}
.header-title .breadcrumb > li > a > .fa,
.header-title .breadcrumb > li > a > .glyphicon,
.header-title .breadcrumb > li > a > .ion {
  margin-right: 5px;
}
.menu {
  background: #e5f3ed;
}
.navbar-custom-menu ul li {
  display: inline-block !important;
  margin-bottom: 0px;
}
.navbar-nav > li > a {
  padding: 4px 9px;
  position: relative;
  color: #405671;
  word-spacing: 2px;
}
.navbar-admission > li > a {
  word-spacing: 0px;
}
.navbar-admission > li > a > span {
  font-size: 12px;
}
.navbar-nav > li > a:hover {
  background: none;
  color: #405671;
}
.navbar-nav > li > a:focus {
  background-color: #222;
  background: #222;
  color: #405671;
  height: 60px;
}
.navbar-nav > li > a {
  height: 60px;
}
.navbar-nav > li > a:visited {
  background-color: #222;
  background: #222;
  color: #405671;
}
.navbar-nav > li > a:active {
  background-color: #eeeeee;
  background: #eeeeee;
  color: #405671;
}
.navbar-nav > li > a > i {
  padding: 10px 3px;
  width: 36px;
  text-align: center;
  color: #7c7c80;
  height: 52px;
  font-size: 28px;
  font-weight: 500;
}
.navbarsmicon > li > a > i {
  padding: 10px 3px;
  width: 36px;
  text-align: center;
  color: #f9f9fb;
  height: 52px;
  font-size: 20px;
  font-weight: 500;
}
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: #222;
  border-color: #222;
}
.navbar-nav > li > a > i:hover {
  padding: 10px 3px;
  width: 36px;
  text-align: center;
  color: #405671;
  height: 52px;
  font-size: 28px;
  font-weight: 500;
}
.navbar-custom-menu > li > a > img {
  max-width: none;
  margin-top: 3px;
  margin-bottom: 3px;
}

@media (max-width: 550px) {
  .navbar-nav > li > a > i {
    padding: 8px 0px;
    width: 21px;
    text-align: center;
    color: #f9f9fb;
    height: 44px;
    font-size: 24px;
  }
  .navbar-nav > li > a > i:hover {
    padding: 8px 0px;
    width: 21px;
    text-align: center;
    color: #f9f9fb;
    height: 44px;
    font-size: 24px;
  }
  .main-header .sidebar-toggle {
    float: left;
    background-image: none;
    padding: 16px 4px;
    color: #374767;
    font-size: 24px;
    line-height: 26px;
    margin-right: 0px;
  }
  .content-header .header-title h1 {
    margin: 0;
    font-size: 18px;
  }
  .content-header {
    margin-bottom: 20px;
  }
}
@media (max-width: 991px) {
  .header-title .breadcrumb {
    position: relative;
    margin-top: 5px;
    top: 0;
    right: 0;
    float: none !important;
    padding-left: 10px;
  }
  .header-title .breadcrumb li:before {
    color: #97a0b3;
  }
  .custom_delete_mes {
    display: none;
  }
  .inbox-date {
    display: none;
  }
}
.navbar-toggle {
  color: #fff;
  border: 0;
  margin: 0;
  padding: 15px 15px;
}
@media (max-width: 991px) {
  .navbar-custom-menu .navbar-nav > li {
    float: left;
  }
  .navbar-custom-menu .navbar-nav {
    margin: 0;
    float: left;
  }
  .navbar-custom-menu .navbar-nav > li > a {
    padding-top: 5px;
    padding-bottom: 2px;
    color: #fff;
  }
}
@media (max-width: 767px) {
  .content-header {
    padding: 16px 16px;
    margin-bottom: 20px;
  }
  .nav > li > a > img {
    max-width: none;
    margin-top: -4px;
  }
  .content-header .header-icon {
    width: 50px;
    font-size: 40px;
  }
  .content-header .header-title {
    margin-left: 50px;
  }
  .main-header {
    position: relative;
  }
  .main-header .logo,
  .main-header .navbar {
    width: 100%;
    float: none !important;
  }
  .main-header .navbar {
    margin: 0 !important;
  }
  .main-header .navbar-custom-menu {
    float: right;
  }
  .navbar-custom-menu .navbar-nav > li > a {
    padding-bottom: 6px;
  }
  .hidden_hidden {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .navbar-collapse.pull-left {
    float: none !important;
  }
  .navbar-collapse.pull-left + .navbar-custom-menu {
    display: block;
    position: absolute;
    top: 0;
    right: 40px;
  }
}
/*--- Searchbox ---*/

.navbar > a > .pe-7s-search {
  margin-top: 6px;
  font-size: 21px;
  font-weight: 500;
  margin-left: -5px;
}
@media (max-width: 567px) {
  .navbar > a > .pe-7s-search {
    margin-top: 19px;
    font-size: 21px;
    font-weight: 500;
    margin-left: 2px;
  }
}
#search {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translate(0px, -100%) scale(0, 0);
  -moz-transform: translate(0px, -100%) scale(0, 0);
  -o-transform: translate(0px, -100%) scale(0, 0);
  -ms-transform: translate(0px, -100%) scale(0, 0);
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
}
#search.open {
  -webkit-transform: translate(0px, 0px) scale(1, 1);
  -moz-transform: translate(0px, 0px) scale(1, 1);
  -o-transform: translate(0px, 0px) scale(1, 1);
  -ms-transform: translate(0px, 0px) scale(1, 1);
  transform: translate(0px, 0px) scale(1, 1);
  opacity: 1;
  z-index: 9999;
}
#search input[type="search"] {
  position: absolute;
  top: 50%;
  width: 100%;
  color: rgb(255, 255, 255);
  background: rgba(0, 0, 0, 0);
  font-size: 60px;
  font-weight: 300;
  text-align: center;
  border: 0px;
  margin: 0px auto;
  margin-top: -51px;
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
}
#search .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 61px;
  margin-left: -45px;
  z-index: 9999;
}
#search .close {
  position: fixed;
  top: 15px;
  right: 15px;
  color: #fff;
  background-color: #405671;
  border-color: #405671;
  opacity: 1;
  padding: 10px 17px;
  font-size: 27px;
  z-index: 9999;
}
@media only screen and (max-width: 500px) {
  #search input[type="search"] {
    font-size: 25px;
  }
  #search {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 380px;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999 !important;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: translate(0px, -100%) scale(0, 0);
    -moz-transform: translate(0px, -100%) scale(0, 0);
    -o-transform: translate(0px, -100%) scale(0, 0);
    -ms-transform: translate(0px, -100%) scale(0, 0);
    transform: translate(0px, -100%) scale(0, 0);
    opacity: 0;
  }
  .hidden_hidden {
    display: none !important;
  }
  .main-header .navbar-custom-menu,
  .main-header .navbar-right {
    margin-right: -3px;
  }
  .navbar > a > .pe-7s-search {
    margin-top: 5px;
  }
  .dropdown-user {
    margin-top: 0px;
  }
  /* 
.navbar-custom-menu .navbar-nav > li > a {
    padding-top: 14px;
    padding-bottom: 2px;
    margin-top: -4px;
} */
}
/*--- Component: Sidebar ---*/

.main-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 60px;
  min-height: 100%;
  width: 250px;
  z-index: 810;
  font-family: "Montserrat", sans-serif;
  -webkit-transition: -webkit-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -webkit-transition: width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
  background-color: #ffffff;
}
@media (max-width: 767px) {
  .main-sidebar,
  .left-side {
    padding-top: 120px;
  }
}
@media (max-width: 767px) {
  .main-sidebar,
  .left-side {
    -webkit-transform: translate(-250px, 0);
    transform: translate(-250px, 0);
  }
}
@media (min-width: 768px) {
  .sidebar-collapse .main-sidebar,
  .sidebar-collapse .left-side {
    -webkit-transform: translate(-230px, 0);
    transform: translate(-230px, 0);
  }
  .custom_option_sel {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .sidebar-open .main-sidebar,
  .sidebar-open .left-side {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.sidebar {
  padding-bottom: 10px;
}
.sidebar-form input:focus {
  border-color: transparent;
}
.user-panel {
  position: relative;
  width: 100%;
  padding: 20px 10px;
  overflow: hidden;
}
.user-panel:before,
.user-panel:after {
  content: " ";
  display: table;
}
.user-panel:after {
  clear: both;
}
.user-panel .image img {
  width: 100%;
  max-width: 65px;
  height: auto;
  border: 2px solid rgba(255, 255, 255, 0.1);
  padding: 5px;
}
.user-panel .info {
  padding: 5px 5px 5px 15px;
  line-height: 1;
}
.user-panel .info p {
  font-weight: 500;
  margin-bottom: 9px;
  color: #fff;
  margin: 5px 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.user-panel .info a {
  text-decoration: none;
  padding-right: 5px;
  margin-top: 3px;
  font-size: 11px;
}
.user-panel .info a .fa,
.user-panel .info a .ion,
.user-panel .info a .glyphicon {
  margin-right: 3px;
}
.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sidebar-menu > li {
  position: relative;
  margin: 0;
  padding: 0;
}
.sidebar-menu > li > a {
  padding: 12px 5px 12px 15px;
  display: block;
  color: #242425;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
}
.sidebar-menu > li > a > i {
  margin-right: 10px;
  margin-left: 10px;
  display: inline-block;
  font-size: 16px;
}
.sidebar-menu > li.active > a:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 3px;
}
.sidebar-menu > li:hover > a,
.sidebar-menu > li.active > a {
  color: #405671;
  background: #eeeeee;
}
.sidebar-menu > li > a > .fa,
.sidebar-menu > li > a > .glyphicon,
.sidebar-menu > li > a > .ion {
  width: 20px;
}
.sidebar-menu > li .label,
.sidebar-menu > li .badge {
  margin-right: 5px;
}
.sidebar-menu > li .badge {
  margin-top: 3px;
}
.sidebar-menu li.header {
  padding: 10px 25px 10px 15px;
  font-size: 12px;
  color: #fff;
}
.sidebar-menu li > a > .fa-angle-left,
.sidebar-menu li > a > .pull-right-container > .fa-angle-left {
  width: auto;
  height: auto;
  padding: 0;
  margin-right: 10px;
}
.sidebar-menu li.active > a > .fa-angle-left,
.sidebar-menu li.active > a > .pull-right-container > .fa-angle-left {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.sidebar-menu li.show > a > .fa-angle-left,
.sidebar-menu li.show > a > .pull-right-container > .fa-angle-left {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.sidebar-menu li.active > .treeview-menu {
  display: block;
  /* display: contents; */
}

.sidebar-menu li.activenest > .treeview-menu {
  display: block;
  /* display: contents; */
}

.sidebar-menu .treeview-menu {
  position: relative;
  display: none;
  list-style: none;
  padding: 5px 0 10px;
  margin: 0;
  padding-left: 34px;
  background-color: #eeeeee;
}
.sidebar-menu .treeview-menu:before {
  width: 2px;
  bottom: 0;
  background: rgba(59, 70, 72, 0.5);
  content: "";
  position: absolute;
  top: 0;
  left: 33px;
}
.sidebar-menu .treeview-menu .treeview-menu {
  padding-left: 34px;
}
.sidebar-menu .treeview-menu > li {
  margin: 0;
  position: relative;
}
.sidebar-menu .treeview-menu > li > a {
  padding: 5px 5px 5px 20px;
  display: block;
  color: #0a0a0b;
  letter-spacing: 0.3px;
  font-weight: 500;
  font-size: 13px;
}
.sidebar-menu .treeview-menu li.disabled a {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.65;
}
.sidebar-menu .treeview-menu > li.active > a {
  color: #405671;
  font-weight: 600;
}
.sidebar-menu .treeview-menu > li.active {
  color: #405671;
  font-weight: 600;
}
.sidebar-menu .treeview-menu > li::before {
  left: 0;
  top: 13px;
  width: 15px;
  content: " ";
  position: absolute;
  display: inline-block;
  border: 1px solid rgba(59, 70, 72, 0.5);
}
.sidebar-menu .treeview-menu > li > a > .fa,
.sidebar-menu .treeview-menu > li > a > .glyphicon,
.sidebar-menu .treeview-menu > li > a > .ion {
  width: 20px;
}
.sidebar-menu .treeview-menu > li > a > .pull-right-container > .fa-angle-left,
.sidebar-menu .treeview-menu > li > a > .pull-right-container > .fa-angle-down,
.sidebar-menu .treeview-menu > li > a > .fa-angle-left,
.sidebar-menu .treeview-menu > li > a > .fa-angle-down {
  width: auto;
}
/*--- Component: Sidebar Mini ---*/

@media (min-width: 768px) {
  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .right-side,
  .sidebar-mini.sidebar-collapse .main-footer {
    margin-left: 50px;
    z-index: 840;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    width: 50px;
    z-index: 850;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li {
    position: relative;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a {
    margin-right: 0;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span {
    border-top-right-radius: 4px;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:not(.treeview) > a > span {
    border-bottom-right-radius: 4px;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom-right-radius: 4px;
  }
  .sidebar-mini.sidebar-collapse
    .sidebar-menu
    > li:hover
    > a
    > span:not(.pull-right),
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {
    display: block !important;
    position: absolute;
    width: 180px;
    left: 50px;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > span {
    top: 0;
    margin-left: 0px;
    padding: 12px 5px 12px 20px;
    background-color: inherit;
  }
  .sidebar-mini.sidebar-collapse
    .sidebar-menu
    > li:hover
    > a
    > .pull-right-container {
    float: right;
    width: auto !important;
    left: 200px !important;
    top: 10px !important;
  }
  .sidebar-mini.sidebar-collapse
    .sidebar-menu
    > li:hover
    > a
    > .pull-right-container
    > .label:not(:first-of-type) {
    display: none;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {
    top: 44px;
    margin-left: 0;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar .user-panel > .info,
  .sidebar-mini.sidebar-collapse .sidebar-form,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > .pull-right,
  .sidebar-mini.sidebar-collapse .sidebar-menu li.header {
    display: none !important;
    -webkit-transform: translateZ(0);
  }
  .sidebar-mini.sidebar-collapse .main-header .logo {
    width: 50px;
  }
  .sidebar-mini.sidebar-collapse .main-header .logo > .logo-mini {
    display: block;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 18px;
  }
  .sidebar-mini.sidebar-collapse .main-header .logo > .logo-lg {
    display: none;
  }
  .sidebar-mini.sidebar-collapse .main-header .navbar {
    margin-left: 50px;
  }
}
.sidebar-menu,
.main-sidebar .user-panel,
.sidebar-menu > li.header {
  white-space: nowrap;
  overflow: hidden;
}
.sidebar-menu:hover {
  overflow: visible;
}
.sidebar-form {
  border-radius: 3px;
  border: 1px solid #1c1f22;
  margin: -20px 0px;
}
.sidebar-form,
.sidebar-menu > li.header {
  overflow: hidden;
  text-overflow: clip;
}
.sidebar-menu > li.header {
  letter-spacing: 1px;
  font-weight: 600;
  border-bottom: 1px solid rgba(59, 70, 72, 0.5);
  color: #a6a6a6;
}
.sidebar-menu li > a > .pull-right-container {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -7px;
}
/* .form-control::-moz-placeholder {
  color: gray;
  opacity: 1;
  font-weight: 500;
} */

/*Dropdowns in general*/

.dropdown-menu {
  box-shadow: none;
  border-color: #e1e6ef;
}
.dropdown-menu > li > a {
  color: #0f0f0f;
  font-weight: 600;
  padding: 5px 20px;
}
.dropdown-menu > li > a:hover {
  color: #405671;
}
.dropdown-menu > .divider {
  background-color: #eee;
}
.navbar-nav > .notifications-menu > .dropdown-menu,
.navbar-nav > .messages-menu > .dropdown-menu,
.navbar-nav > .tasks-menu > .dropdown-menu {
  width: 280px;
  padding: 0 0 0 0;
  margin: 0;
  top: 100%;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li,
.navbar-nav > .messages-menu > .dropdown-menu > li,
.navbar-nav > .tasks-menu > .dropdown-menu > li {
  position: relative;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li.header,
.navbar-nav > .messages-menu > .dropdown-menu > li.header,
.navbar-nav > .tasks-menu > .dropdown-menu > li.header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #ffffff;
  padding: 7px 10px;
  border-bottom: 1px solid #e1e6ef;
  color: #444444;
  font-size: 14px;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a,
.navbar-nav > .messages-menu > .dropdown-menu > li.footer > a,
.navbar-nav > .tasks-menu > .dropdown-menu > li.footer > a {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size: 12px;
  background-color: #fff;
  padding: 7px 10px;
  border-top: 1px solid #e1e6ef;
  color: #444;
  text-align: center;
}
.navbar-nav > .navbar-custom-menu > .nav > li > a:hover {
  background-color: yellow;
}
@media (max-width: 991px) {
  .navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a,
  .navbar-nav > .messages-menu > .dropdown-menu > li.footer > a,
  .navbar-nav > .tasks-menu > .dropdown-menu > li.footer > a {
    background: #fff;
    color: #444;
  }
  .custom_option_sel {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .custom_p_version h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .mailbox-sideber {
    display: none !important;
  }
  .custom_mail {
    display: none !important;
  }
}
.navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a:hover,
.navbar-nav > .messages-menu > .dropdown-menu > li.footer > a:hover,
.navbar-nav > .tasks-menu > .dropdown-menu > li.footer > a:hover {
  text-decoration: none;
  font-weight: normal;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu,
.navbar-nav > .messages-menu > .dropdown-menu > li .menu,
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu {
  max-height: 200px;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-x: hidden;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a,
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a,
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a {
  display: block;
  white-space: nowrap;
  /* Prevent text from breaking */

  border-bottom: 1px solid #f4f4f4;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a:hover,
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:hover,
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a:hover {
  background: #f0f3f1;
  text-decoration: none;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a {
  color: #0e0d0d;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  font-size: 13px;
  font-weight: 700;
}
.navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a > i {
  width: 16px;
  font-size: 16px;
  margin-right: 10px;
}
.navbar-nav > .messages-menu > .dropdown-menu > a {
  margin: 0;
  padding: 0px;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > div > img {
  margin: auto 10px auto auto;
  width: 40px;
  height: 40px;
  border: 1px solid #908c8c;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > h4 {
  padding: 0;
  margin: 0 0 0 45px;
  color: #222;
  font-size: 15px;
  position: relative;
  font-weight: 700;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:hover {
  background-color: #f9f9f9;
}
.menue {
  padding: 10px 3px 0px 11px;
}
.left_item img {
  width: 40px;
  height: 40px;
}
.left_item {
  float: left;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > h4 > small {
  color: #999999;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > p {
  font-size: 12px;
  color: #0e0d0d;
  margin-bottom: 7px;
  font-weight: 600;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:before,
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:after {
  content: " ";
  display: table;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:after {
  clear: both;
}
.navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:after {
  clear: both;
}
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a {
  padding: 10px;
}
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a > h3 {
  font-size: 13px;
  margin: 0 0 4px 0;
  color: #090909;
  word-spacing: 0px;
  font-weight: 600;
}
.navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a > .progress {
  padding: 0;
  margin: 0;
}
.navbar-nav > .dropdown-user > .dropdown-menu > li > a {
  padding: 5px 10px;
}
.navbar-nav > .dropdown-user > .dropdown-menu > li > a > i {
  font-size: 16px;
  margin-right: 10px;
}
.img-thumbnail {
  padding: 1px;
  border-radius: 0px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 40px;
  height: 40px;
  border: 1px solid #908c8c;
}
.right_item {
  padding-left: 50px;
  margin-top: -1px;
  padding-bottom: 7px;
}
.right_item h4 {
  padding: 0;
  color: #222;
  font-size: 15px;
  position: relative;
  font-weight: 700;
}
.right_item p {
  font-size: 11px;
  color: #0e0d0d;
  font-weight: 600;
}
.border_border {
  border-top: 1px solid#ddd;
}
/* Add fade animation to dropdown menus by appending
the class .animated-dropdown-menu to the .dropdown-menu ul (or ol)*/

.open:not(.dropup) > .animated-dropdown-menu {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation: flipInX 0.7s both;
  animation: flipInX 0.7s both;
}
@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}
@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  100% {
    -webkit-transform: perspective(400px);
  }
}
/* Fix dropdown menu in navbars */

.navbar-custom-menu > .navbar-nav > li {
  position: relative;
}
.navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
  position: absolute;
  right: -3px;
  left: auto;
  top: 60px;
}

@media (max-width: 991px) {
  .navbar-custom-menu > .navbar-nav {
    float: right;
  }
  .navbar-custom-menu > .navbar-nav > li {
    position: static;
  }
  .navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
    position: absolute;
    right: 5%;
    left: auto;
    border: 1px solid #ddd;
    background: #fff;
  }
}

/*Dashboard Component:cards*/

.statistic-box h3 {
  font-size: 23px;
}
.statistic-box {
  padding: 12px;
}
.statistic-box a {
  color: Green;
}
.statistic-box h3 {
  margin-top: 5px;
  font-weight: 600;
}
.statistic-box-footer i.fa {
  color: #fef7f7;
}
.statistic-box-footer {
  color: #fef7f7;
  background: #36363f99;
  padding: 3px 69px;
  font-size: 14px;
}
#cardbox1 {
  -moz-box-shadow: 0 0 5px #888;
  -webkit-box-shadow: 0 0 5px #888;
  box-shadow: 0 0 5px #888;
  color: #e4e5e7;
  cursor: pointer;
  background-color: #405671;
  height: 130px;
  margin-bottom: 25px;
  border-radius: 4px;
}
#cardbox2 {
  -moz-box-shadow: 0 0 5px #888;
  -webkit-box-shadow: 0 0 5px #888;
  box-shadow: 0 0 5px #888;
  color: #e4e5e7;
  cursor: pointer;
  background-color: #405671;
  height: 130px;
  margin-bottom: 25px;
  border-radius: 4px;
}
#cardbox3 {
  -moz-box-shadow: 0 0 5px #888;
  -webkit-box-shadow: 0 0 5px #888;
  box-shadow: 0 0 5px #888;
  color: #e4e5e7;
  cursor: pointer;
  background-color: #405671;
  height: 130px;
  margin-bottom: 25px;
  border-radius: 4px;
}
#cardbox4 {
  -moz-box-shadow: 0 0 5px #888;
  -webkit-box-shadow: 0 0 5px #888;
  box-shadow: 0 0 5px #888;
  color: #e4e5e7;
  cursor: pointer;
  background-color: #405671;
  height: 130px;
  margin-bottom: 25px;
  border-radius: 4px;
}
#cardbox1:hover,
#cardbox1:focus,
#cardbox2:hover,
#cardbox2:focus,
#cardbox3:hover,
#cardbox3:focus,
#cardbox4:hover,
#cardbox4:focus {
  color: #fff;
  cursor: pointer;
  background: #405671;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
span.count-number {
  color: #e4e5e7;
  font-size: 20px;
  font-weight: bold;
}
.income {
  width: 220px;
  margin-left: 15px;
}
.payments {
  width: 220px;
  margin-left: 15px;
}
.expence {
  width: 220px;
  margin-left: 15px;
}
.searchdate {
  margin-top: -34px;
  margin-right: 35px;
  background-color: #405671;
}
.yearlabel {
  padding-left: 15px;
}
.statistic-box .small {
  font-weight: 600;
  color: #222;
  margin-bottom: 15px;
}
.slight {
  font-size: 13px;
}
small,
.small {
  font-size: 85%;
}
.canvasjs-chart-credit {
  display: none;
}
i.fa-ban {
  color: red;
}
/*Dashboard Component:upcoming works*/

.detailswork p {
  border-bottom: 1px solid gray;
  padding-bottom: 6px;
  color: #222;
  font-weight: 500;
  font-size: 14px;
}
.detailswork a {
  color: #222;
  font-weight: 600;
  font-size: 14px;
}
.work-touchpoint-date {
  float: left;
  margin: 0 10px 0 0;
  border: 1px solid gray;
  background: #f2f2f2;
}
.work-touchpoint-date2 {
  float: left;
  margin: 0 10px 0 0;
  border: 1px solid gray;
  background: #f2f2f2;
}
.work-touchpoint-date .day {
  color: #323232;
  display: block;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  padding: 4px 0;
  text-align: center;
  width: 30px;
}
.work-touchpoint-date2 .day {
  color: #323232;
  display: block;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  padding: 4px 0;
  text-align: center;
  width: 30px;
}
.work-touchpoint-date .month {
  background-color: #405671;
  color: #fff;
  display: block;
  font-size: 9px;
  font-weight: 500;
  line-height: 1;
  padding: 3px 0 3px 0;
  text-align: center;
  text-transform: uppercase;
  width: 30px;
}
.work-touchpoint-date2 .month {
  background-color: #222;
  color: #fff;
  display: block;
  font-size: 9px;
  font-weight: 500;
  line-height: 1;
  padding: 3px 0 3px 0;
  text-align: center;
  text-transform: uppercase;
  width: 30px;
}
.runnigwork a {
  color: #222;
  font-weight: 600;
  font-size: 14px;
}
i.fa-dot-circle-o {
  color: #405671;
}
.Pendingwork a {
  color: #222;
  font-weight: 700;
  font-size: 14px;
}
.Pendingwork p {
  color: #222;
  font-size: 11px;
  font-weight: 600;
}
.Pendingwork {
  padding-bottom: 2px;
}
/*Dashboard Component:Calender*/

#calendar1 {
  margin: 0 auto;
}
#calendar1 .fc-toolbar h2 {
  font-size: 16px;
  margin: 6px 0;
}
.custom_calend p {
  font-size: 13px;
}
.custom_calend h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

/*Dashboard Component:Google map*/

.google-maps {
  position: relative;
  padding-bottom: 49%;
  height: 0;
  overflow: hidden;
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 380px;
  border: none;
}
/*--- UI Elements:  ---*/
/**
/*UI Elements:Panel page
==============================================================================*/

.panel {
  box-shadow: none;
  border-radius: 0px;
  border: none;
}
.panel .panel-heading h1,
.panel .panel-heading h2,
.panel .panel-heading h3,
.panel .panel-heading h4,
.panel .panel-heading h5,
.panel .panel-heading h6 {
  margin: 0;
  line-height: 26px;
  letter-spacing: 0.5px;
  color: #222;
  font-weight: 600;
}
.lobipanel {
  margin-bottom: 25px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.panel-bd > .panel-heading {
  color: #010611;
  background-color: #e8f1f3;
  border-color: #b7b9bf;
  position: relative;
}
.panel-primary > .panel-heading {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}
.panel-success > .panel-heading {
  color: #fff;
  background-color: #50ab20;
  border-color: #50ab20;
}
.panel-info > .panel-heading {
  color: #fff;
  background-color: #62d0f1;
  border-color: #62d0f1;
}
.panel-warning > .panel-heading {
  color: #fff;
  background-color: #ffc751;
  border-color: #ffc751;
}
.panel-danger > .panel-heading {
  color: #fff;
  background-color: #e5343d;
  border-color: #e5343d;
}
.panel-inverse > .panel-heading {
  color: #fff;
  background-color: #3b3e47;
  border-color: #3b3e47;
}
.panel-custom > .custom_head {
  color: #060606;
  background-color: #b1d3db;
  border-color: #b1eaec;
  padding: 15px 10px 15px 15px;
}
.panel-footer {
  background-color: #f7f9fa;
  border-top: 1px solid #e1e6ef;
}

@media (min-width: 768px) {
  .panel-primary.lobipanel .panel-heading .dropdown .dropdown-menu > li > a,
  .panel-success.lobipanel .panel-heading .dropdown .dropdown-menu > li > a,
  .panel-info.lobipanel .panel-heading .dropdown .dropdown-menu > li > a,
  .panel-warning.lobipanel .panel-heading .dropdown .dropdown-menu > li > a,
  .panel-danger.lobipanel .panel-heading .dropdown .dropdown-menu > li > a,
  .panel-inverse.lobipanel .panel-heading .dropdown .dropdown-menu > li > a {
    color: #fff;
  }
}
/*** Buttons page
==============================================================================*/

/* .btn {
  border-radius: 2px;
} */
/* .btn-rounded {
  border-radius: 2em;
}
.w-md {
  min-width: 110px;
}
.btn-primary,
.btn-primary:hover,
.btn-success,
.btn-success:hover,
.btn-warning,
.btn-warning:hover,
.btn-danger,
.btn-danger:hover,
.btn-inverse,
.btn-inverse:hover,
.btn-purple,
.btn-purple:hover,
.btn-pink,
.btn-pink:hover,
.btn-black,
.btn-black:hover,
.btn-violet,
.btn-violet:hover {
  color: #fff;
} */
/*--- Buttons default ---*/

/* .btn-default.btn-transparent {
  color: rgb(206, 208, 210);
  background-color: rgba(206, 208, 210, 0.2);
  border-color: rgba(206, 208, 210, 0.3);
}
.btn-default {
  border: 1px solid#ddd;
}

.rating-block .btn-rating .fa {
  font-size: 14px;
  padding: 7px 4px 7px 4px;
  text-align: center;
}
.rating-block .btn-default .fa {
  font-size: 14px;
  padding: 7px 4px 7px 4px;
  text-align: center;
}
.rating-block h4 {
  font-size: 18px;
  margin-bottom: 20px;
}
.rating-block h2 > small {
  font-size: 18px;
  color: #777;
}
.review-block-rate .btn-rating .fa {
  font-size: 11px;
  padding: 7px 4px 7px 4px;
  text-align: center;
}
.review-block-rate .btn-default .fa {
  font-size: 11px;
  padding: 7px 4px 7px 4px;
  text-align: center;
}
.review-block {
  margin-top: 20px;
}
.btn-default:focus {
  border: 1px solid#ddd;
  outline: 0;
  box-shadow: none;
}
.raiting_rate {
  font-size: 18px;
  margin-bottom: 20px;
}
.btn-rating:focus {
  border: 1px solid#ddd;
  outline: 0;
  box-shadow: none;
}
.btn-default.btn-transparent:hover,
.btn-default.btn-transparent:focus,
.btn-default.btn-transparent:active {
  color: rgb(206, 208, 210);
  background-color: rgba(206, 208, 210, 0.2);
  border-color: rgba(206, 208, 210, 0.3);
}
.btn-default.btn-outline {
  color: #ced0d2;
  background-color: transparent;
}
.btn-default.btn-outline:hover,
.btn-default.btn-outline:focus,
.btn-default.btn-outline:active {
  color: #fff;
  background-color: #cacbcc;
} */
/*--- Buttons primary ---*/

/*** Tabs page
==============================================================================*/

.nav-tabs {
  border-bottom: 0;
}
.mycontent {
  border: 1px solid#000;
  box-sizing: border-box;
  padding: 18px;
  margin-top: 1px;
}
.card_card ul li a {
  font-size: 14px;
}
.card_body_text p {
  font-size: 14px;
}
.card_body_text span {
  font-size: 14px;
}
.card_card ul > li > a:focus,
.card_card ul > li > a:hover {
  text-decoration: none;
  background-color: #eee;
}
.card_shadow {
  box-shadow: none !important;
  border: none !important;
  border-radius: 0px 0px 0px 0px;
}
.nav-tabs > li > a {
  color: #222;
  font-weight: 600;
}
.tab-content .panel-body {
  background: #fff;
  border: 1px solid gray;
  border-radius: 2px;
  padding: 20px;
  position: relative;
}
.custom_panel h3 {
  font-size: 24px;
  padding-bottom: 5px;
}
.custom_panel a {
  font-size: 14px;
}
.padding_heading {
  padding: 0px 0px 0px 0px;
  border-bottom: 0px solid transparent;
}
.padding_heading a {
  text-decoration: none !important;
  color: #405671;
}
.padding_heading a:hover {
  color: #62bcb4;
}

.custom_decoration a:hover {
  text-decoration: none;
}
#btn-date {
  margin-bottom: 10px;
}
@media (max-width: 499px) {
  .tab-content .panel-body {
    background: #fff;
    border: 1px solid gray;
    border-radius: 2px;
    padding: 20px;
    position: relative;
    margin-top: 15px;
  }
  .full_tab_content {
    margin-top: 30px;
  }
}

.tabs-left > li,
.tabs-right > li {
  float: none;
}
.tabs-left > li {
  margin-right: -1px;
}
.tabs-right > li {
  margin-left: -1px;
}
.tabs-left > li.active > a,
.tabs-left > li.active > a:hover,
.tabs-left > li.active > a:focus {
  border-bottom-color: #405671;
  border-right-color: #405671;
  background-color: #405671;
  color: #fff;
}
.tabs-right > li.active > a,
.tabs-right > li.active > a:hover,
.tabs-right > li.active > a:focus {
  border-bottom: 1px solid #405671;
  border-left-color: transparent;
}
.tabs-left > li > a {
  border-radius: 4px 0 0 4px;
  margin-right: 0;
  display: block;
}
.tabs-right > li > a {
  border-radius: 0 4px 4px 0;
  margin-right: 0;
}
.sideways {
  border: none;
  position: relative;
  padding-top: 36px;
  margin-top: 4px;
}
.sideways a {
  text-align: center;
  width: 126px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  margin-bottom: 74px;
  margin-left: -21px;
}
.sideways2 a {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-bottom: 26px;
  margin-left: -16px;
  margin-right: -57px;
  margin-bottom: 76px;
}
.sideways2 {
  margin-top: 28px;
}
.rotate_a {
  margin-left: -20px !important;
}
.rotate_a2 {
  margin-left: -11px !important;
}

@media (max-width: 767px) {
  .sideways.tabs-left {
    left: -44px;
  }
}
@media (max-width: 768px) {
  .sideways.tabs-left {
    left: -44px;
  }
  .sideways a {
    margin-left: -30px !important;
  }
  .custom_modal_text h1 {
    font-size: 25px !important;
  }
  .custom_modal_text h4 {
    font-size: 18px !important;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
  }
}
/*** Notifications page
==============================================================================*/

pre {
  background-color: #2c3136;
  color: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
  text-align: left;
  font-size: 14px;
  overflow: hidden;
  border: 1px solid #2c3136;
}
.codrops-header h1 {
  margin: 20px 0;
  font-size: 47px;
  line-height: 1;
}
.btn_size {
  width: 105px;
}
pre .str {
  color: #e6db74;
}
pre .func {
  color: #66d9ef;
}
pre .val {
  color: #a381ff;
}
pre .tag {
  color: #e92772;
}
pre .attr {
  color: #a6e22d;
}
pre .arg {
  color: #fd9720;
}
/*** Tree View
==============================================================================*/

.well {
  height: 135px;
  box-shadow: none;
}
.tree,
.tree ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tree ul {
  margin-left: 1em;
  position: relative;
}
.tree ul ul {
  margin-left: 0.5em;
}
.tree ul:before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 1px solid;
}
.tree li {
  margin: 0;
  padding: 0 1em;
  line-height: 2em;
  color: #4c4c4c;
  font-weight: 600;
  position: relative;
  cursor: pointer;
}
.tree ul li:before {
  content: "";
  display: block;
  width: 10px;
  height: 0;
  border-top: 1px solid;
  margin-top: -1px;
  position: absolute;
  top: 1em;
  left: 0;
}
.tree ul li:last-child:before {
  background: #fff;
  height: auto;
  top: 1em;
  bottom: 0;
}
.indicator {
  margin-right: 5px;
}
.tree li a {
  text-decoration: none;
  color: #4c4c4c;
}
.tree li a:hover {
  color: #405671;
}
.tree li button,
.tree li button:active,
.tree li button:focus {
  text-decoration: none;
  color: #369;
  border: none;
  background: transparent;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  outline: 0;
}
/*** progressbars page
==============================================================================*/

.progress {
  -webkit-box-shadow: none;
  background-color: #e0e0e0;
  box-shadow: none;
  height: 6px;
  overflow: visible;
  margin: 10px 17px 10px;
}
.progress-bar {
  box-shadow: none;
  font-size: 8px;
  font-weight: 600;
  line-height: 12px;
  background-color: #405671;
}
.progress-animated {
  -webkit-animation-duration: 5s;
  -webkit-animation-name: animationProgress;
  -webkit-transition: 5s all;
  animation-duration: 5s;
  animation-name: animationProgress;
  transition: 5s all;
}
.progress.progress-sm {
  height: 5px;
}
.progress.progress-md {
  height: 15px;
}
.progress.progress-lg {
  height: 20px;
}
.progress.progress-md .progress-bar {
  font-size: 10.8px;
  line-height: 14.4px;
}
.progress.progress-lg .progress-bar {
  font-size: 12px;
  line-height: 20px;
}
.progress-bar-primary {
  background-color: #428bca;
}
.progress-bar-success {
  background-color: #50ab20;
}
.progress-bar-info {
  background-color: #62d0f1;
}
.progress-bar-warning {
  background-color: #ffb61e;
}
.progress-bar-danger {
  background-color: #e5343d;
}
.progress-bar-inverse {
  background-color: #3b3e47;
}
.progress-bar-pink {
  background-color: #ff8acc;
}
.progress-bar-violet {
  background-color: #8e23e0;
}
.progress-bar .tooltip {
  position: relative;
  float: right;
}
.progress-bar .tooltip .tooltip-inner {
  background-color: #f5f5f5;
  padding: 4px 8px;
  color: #fff;
  font-weight: bold;
  font-size: 9px;
}
.popOver + .tooltip .tooltip-arrow {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
}
.progress-bar-primary .tooltip .tooltip-inner {
  background-color: #428bca;
}
.progress-bar-primary .tooltip.top .tooltip-arrow {
  border-top: 5px solid #428bca;
}
.progress-bar-success .tooltip .tooltip-inner {
  background-color: #50ab20;
}
.progress-bar-success .tooltip.top .tooltip-arrow {
  border-top: 5px solid #50ab20;
}
.progress-bar-info .tooltip .tooltip-inner {
  background-color: #62d0f1;
}
.progress-bar-info .tooltip.top .tooltip-arrow {
  border-top: 5px solid #62d0f1;
}
.progress-bar-warning .tooltip .tooltip-inner {
  background-color: #ffb61e;
}
.progress-bar-warning .tooltip.top .tooltip-arrow {
  border-top: 5px solid #ffb61e;
}
.progress-bar-danger .tooltip .tooltip-inner {
  background-color: #e5343d;
}
.progress-bar-danger .tooltip.top .tooltip-arrow {
  border-top: 5px solid #e5343d;
}
.progress-bar-inverse .tooltip .tooltip-inner {
  background-color: #3b3e47;
}
.progress-bar-inverse .tooltip.top .tooltip-arrow {
  border-top: 5px solid #3b3e47;
}
.progress-bar-pink .tooltip .tooltip-inner {
  background-color: #ff8acc;
}
.progress-bar-pink .tooltip.top .tooltip-arrow {
  border-top: 5px solid #ff8acc;
}
.progress-bar-violet .tooltip .tooltip-inner {
  background-color: #8e23e0;
}
.progress-bar-violet .tooltip.top .tooltip-arrow {
  border-top: 5px solid #8e23e0;
}
.textbox {
  height: 700px;
  height: 10px;
}
.progress-bars {
  line-height: 1rem;
  color: #fff;
  background-color: #007bff;
  transition: width 0.6s ease;
  height: 10px;
  line-height: 10px;
}
/** pregress css**/

.custom_progress ul {
  list-style: none;
}
.progress_bar_bg:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
}
.skills-bar-container li {
  position: relative;
  width: 85%;
  padding: 5px;
  box-sizing: border-box;
}
.skills-bar-container li .progressbar-title {
  color: #fff;
}
.skills-bar-container li .progressbar-title h3 {
  display: inline-block;
  font-size: 20px;
}
.skills-bar-container li .progressbar-title .percent {
  position: absolute;
  right: 5px;
  color: #008a8a;
  font-size: 10px;
  z-index: 999;
  top: -4px;
}
.skills-bar-container li .bar-container {
  background: #555;
  position: relative;
  width: 100%;
  height: 7px;
  margin-top: 5px;
  display: block;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
.skills-bar-container li .bar-container .progressbar {
  position: absolute;
  width: 0%;
  height: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.skills-bar-container li .bar-container #progress-html {
  -webkit-animation-name: progress-html;
  animation-name: progress-html;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
.skills-bar-container li .bar-container #progress-css {
  -webkit-animation-name: progress-css;
  animation-name: progress-css;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}
.skills-bar-container li .bar-container #progress-javascript {
  -webkit-animation-name: progress-javascript;
  animation-name: progress-javascript;
  -webkit-animation-delay: 2.1s;
  animation-delay: 2.1s;
}
.skills-bar-container li .bar-container #progress-php {
  -webkit-animation-name: progress-php;
  animation-name: progress-php;
  -webkit-animation-delay: 2.8s;
  animation-delay: 2.8s;
}
.skills-bar-container li .bar-container #progress-angular {
  -webkit-animation-name: progress-angular;
  animation-name: progress-angular;
  -webkit-animation-delay: 3.5s;
  animation-delay: 3.5s;
}
.skills-bar-container li .bar-container #progress-htmls {
  -webkit-animation-name: progress-html;
  animation-name: progress-html;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
.skills-bar-container li .bar-container #progress-csss {
  -webkit-animation-name: progress-css;
  animation-name: progress-css;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}
.skills-bar-container li .bar-container #progress-javascripts {
  -webkit-animation-name: progress-javascript;
  animation-name: progress-javascript;
  -webkit-animation-delay: 2.1s;
  animation-delay: 2.1s;
}
.skills-bar-container li .bar-container #progress-phps {
  -webkit-animation-name: progress-php;
  animation-name: progress-php;
  -webkit-animation-delay: 2.8s;
  animation-delay: 2.8s;
}
.skills-bar-container li .bar-container #progress-angulars {
  -webkit-animation-name: progress-angular;
  animation-name: progress-angular;
  -webkit-animation-delay: 3.5s;
  animation-delay: 3.5s;
}

.progressred {
  background-color: #c0392b;
}

.progressblue {
  background-color: #1199ff;
}

.progresspurple {
  background-color: #9b59b6;
}

.progressorange {
  background-color: #ffa500;
}

.progressgreen {
  background-color: #27ae60;
}

@-webkit-keyframes progress-html {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@-webkit-keyframes progress-css {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}
@-webkit-keyframes progress-javascript {
  0% {
    width: 0%;
  }
  100% {
    width: 70%;
  }
}
@-webkit-keyframes progress-php {
  0% {
    width: 0%;
  }
  100% {
    width: 55%;
  }
}
@-webkit-keyframes progress-angular {
  0% {
    width: 0%;
  }
  100% {
    width: 65%;
  }
}
@-moz-keyframes progress-html {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@-moz-keyframes progress-css {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}
@-moz-keyframes progress-javascript {
  0% {
    width: 0%;
  }
  100% {
    width: 70%;
  }
}
@-moz-keyframes progress-php {
  0% {
    width: 0%;
  }
  100% {
    width: 55%;
  }
}
@-moz-keyframes progress-angular {
  0% {
    width: 0%;
  }
  100% {
    width: 65%;
  }
}
@keyframes progress-html {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes progress-css {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}
@keyframes progress-javascript {
  0% {
    width: 0%;
  }
  100% {
    width: 70%;
  }
}
@keyframes progress-php {
  0% {
    width: 0%;
  }
  100% {
    width: 55%;
  }
}
@keyframes progress-angular {
  0% {
    width: 0%;
  }
  100% {
    width: 65%;
  }
}
@-webkit-keyframes progress-htmls {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@-webkit-keyframes progress-csss {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}
@-webkit-keyframes progress-javascripts {
  0% {
    width: 0%;
  }
  100% {
    width: 70%;
  }
}
@-webkit-keyframes progress-phps {
  0% {
    width: 0%;
  }
  100% {
    width: 55%;
  }
}
@-webkit-keyframes progress-angulars {
  0% {
    width: 0%;
  }
  100% {
    width: 65%;
  }
}
@-moz-keyframes progress-htmls {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@-moz-keyframes progress-csss {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}
@-moz-keyframes progress-javascripts {
  0% {
    width: 0%;
  }
  100% {
    width: 70%;
  }
}
@-moz-keyframes progress-phps {
  0% {
    width: 0%;
  }
  100% {
    width: 55%;
  }
}
@-moz-keyframes progress-angulars {
  0% {
    width: 0%;
  }
  100% {
    width: 65%;
  }
}
@keyframes progress-htmls {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes progress-csss {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}
@keyframes progress-javascripts {
  0% {
    width: 0%;
  }
  100% {
    width: 70%;
  }
}
@keyframes progress-phps {
  0% {
    width: 0%;
  }
  100% {
    width: 55%;
  }
}
@keyframes progress-angulars {
  0% {
    width: 0%;
  }
  100% {
    width: 65%;
  }
}
/** pregress css**/
.progress-bars {
  width: 0;
}
.bar_p1 {
  background: #428bca;
}
.bar_p2 {
  background: #50ab20;
}
.bar_p3 {
  background: #62d0f1;
}
.bar_p4 {
  background: #ffb61e;
}
.bar_p5 {
  background: #e5343d;
}
.bar_p6 {
  background: #3b3e47;
}
.bar_p7 {
  background: #ff8acc;
}
.bar_p8 {
  background: #8e23e0;
}
/*** List view
==============================================================================*/
/*--- Nestable ---*/

.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  max-width: 600px;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}
.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}
.dd-list .dd-list {
  padding-left: 30px;
}
.dd-item {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}
.dd-handle {
  display: block;
  margin: 5px 0;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
  border: 1px solid #e4e5e7;
  background: #f7f9fa;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  cursor: pointer;
}
.dd-handle:hover {
  color: #104e75;
  background: #fff;
}
.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
}
.dd-item > button:before {
  content: "+";
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}
.dd-item > button[data-action="collapse"]:before {
  content: "-";
}
#nestable .dd-handle {
  color: inherit;
  border: 1px dashed #e4e5e7;
  background: #f7f9fa;
  padding: 7px 10px;
}
.custom_typo_text p {
  font-size: 14px;
}
.custom_typo_text ol li {
  font-size: 14px;
}
.custom_typo_text ul li {
  font-size: 14px;
}
.custom_typo_text address {
  font-size: 14px;
}
#nestable .dd-handle:hover {
  color: #178eda;
  background: #fff;
}
#nestable-output,
#nestable2-output {
  font-size: 12px;
  padding: 25px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
#nestable .dd-item > button {
  font-family: FontAwesome;
  height: 34px;
  width: 33px;
  color: #c1c1c1;
}
#nestable .dd-item > button[data-action="collapse"]::before {
  content: "\f068";
}
#nestable .dd-item > button::before {
  content: "\f067";
}
#nestable span.label {
  margin-right: 10px;
}
.dd-handle span {
  font-weight: bold;
}
/*** Typography page
==============================================================================*/

.headding_ex h1,
.headding_ex h2,
.headding_ex h3,
.headding_ex h4,
.headding_ex h5,
.headding_ex h6 {
  margin: 0;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* Modals page
==============================================================================*/
/*-- Nifty modal --*/

.modal-text-header h1 {
  font-size: 2.625em;
  line-height: 1.3;
  font-weight: 300;
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #e1e6ef;
  margin: 0 -15px 15px;
}
.custom_modal_text h1 {
  font-size: 40px;
}
.custom_modal_text span {
  font-size: 18px !important;
}
.custom_modal_text p {
  font-size: 14px !important;
}
.modal-text2 {
  font-size: 30px !important;
  text-align: right;
  line-height: 40px;
}
.custom_grreen {
  font-size: 14px;
}
.modal-text-header span {
  display: block;
  font-size: 60%;
  opacity: 0.7;
  padding: 0 0 0.6em 0.1em;
}
.column .modal-text {
  font-weight: 300;
  font-size: 18px;
  padding: 0;
  line-height: 1.5;
}
@media (max-width: 767px) {
  .column {
    padding: 0;
  }
  .column .modal-text {
    text-align: left;
  }
  .modal-text-header h1 {
    margin-bottom: 15px;
  }
  .mailbox-sideber {
    display: none;
  }
  .inbox-avatar-text {
    display: none;
  }
}
@media (min-width: 1200px) {
  .column {
    padding: 2em;
    position: relative;
  }
  .modal-text-header h1 {
    margin-bottom: 20px;
  }
  .column .modal-text {
    text-align: right;
    font-size: 2em;
  }
}
/*-- Bootstrap modal --*/

/* .modal-content {
  border-radius: 0;
} */
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e1e6ef;
  background-color: #f7f9fa;
}
.modal-header h3 {
  font-size: 24px;
}
.modal-title {
  text-align: center;
}
.modal-success .modal-header {
  background-color: #50ab20;
}
.modal-success .modal-header .modal-title,
.modal-primary .modal-header .modal-title,
.modal-warning .modal-header .modal-title,
.modal-danger .modal-header .modal-title {
  color: #fff;
}
.modal-primary .modal-header {
  background-color: #428bca;
}
.modal-warning .modal-header {
  background-color: #ffb61e;
}
.modal-danger .modal-header {
  background-color: #e5343d;
}
/*iCheck, Toggle page
==============================================================================*/
/*--- iCheck ---*/

.i-check {
  margin: 5px 0;
}
.i-check label {
  cursor: pointer;
}
/*--- Toggle ---*/

.toggle-example .toggle {
  margin-right: 10px;
  margin-bottom: 10px;
}
.toggle.ios,
.toggle-on.ios,
.toggle-off.ios {
  border-radius: 20px;
}
.custom_lebel {
  font-size: 14px;
}
.custom_btn_class h4 {
  font-size: 24px;
  margin-bottom: 10px;
}
.custom_btn_class p {
  font-size: 14px;
}
.custom_btn_class2:focus {
  outline: 0;
  box-shadow: none;
  background: #405671;
  border: 1px solid #405671;
}
.custom_btn_class2:hover {
  background: #405671;
  color: #fff;
}
.custom_btn_class2 {
  background: #405671;
  color: #fff;
  border: 1px solid #405671;
}
.custom_btn_class2:hover {
  background: #405671;
  color: #fff;
  border: 1px solid #405671;
}
.custom-checkbox-btn h4 {
  font-size: 18px;
}
.toggle.ios .toggle-handle {
  border-radius: 20px;
}
.toggle.android {
  border-radius: 0px;
}
.toggle.android .toggle-handle {
  border-radius: 0px;
}
.slow .toggle-group {
  transition: left 0.7s;
  -webkit-transition: left 0.7s;
}
.fast .toggle-group {
  transition: left 0.1s;
  -webkit-transition: left 0.1s;
}
.quick .toggle-group {
  transition: none;
  -webkit-transition: none;
}
/*--- checkbox ---*/

.checkbox {
  padding-left: 20px;
}
.checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 5px;
}
.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #555555;
}
.checkbox input[type="checkbox"] {
  opacity: 0;
}
.checkbox input[type="checkbox"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.checkbox input[type="checkbox"]:checked + label::after {
  font-family: "FontAwesome";
  content: "\f00c";
}
.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.65;
}
.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}
.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}
.checkbox.checkbox-inline {
  margin-top: 0;
}
.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #428bca;
  border-color: #428bca;
}
.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #e5343d;
  border-color: #d9534f;
}
.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff;
}
.radio {
  padding-left: 20px;
}
.radio label {
  display: inline-block;
  position: relative;
  padding-left: 5px;
}
.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}
.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 11px;
  height: 11px;
  left: 3px;
  top: 3px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #555555;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33),
    -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}
.radio input[type="radio"] {
  opacity: 0;
}
.radio input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}
.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}
.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}
.radio.radio-inline {
  margin-top: 0;
}
.radio-primary input[type="radio"] + label::after {
  background-color: #428bca;
}
.radio-primary input[type="radio"]:checked + label::before {
  border-color: #428bca;
}
.radio-primary input[type="radio"]:checked + label::after {
  background-color: #428bca;
}
.radio-danger input[type="radio"] + label::after {
  background-color: #d9534f;
}
.radio-danger input[type="radio"]:checked + label::before {
  border-color: #d9534f;
}
.radio-danger input[type="radio"]:checked + label::after {
  background-color: #d9534f;
}
.radio-info input[type="radio"] + label::after {
  background-color: #5bc0de;
}
.radio-info input[type="radio"]:checked + label::before {
  border-color: #5bc0de;
}
.radio-info input[type="radio"]:checked + label::after {
  background-color: #5bc0de;
}
.radio-warning input[type="radio"] + label::after {
  background-color: #f0ad4e;
}
.radio-warning input[type="radio"]:checked + label::before {
  border-color: #f0ad4e;
}
.radio-warning input[type="radio"]:checked + label::after {
  background-color: #f0ad4e;
}
.radio-success input[type="radio"] + label::after {
  background-color: #5cb85c;
}
.radio-success input[type="radio"]:checked + label::before {
  border-color: #5cb85c;
}
.radio-success input[type="radio"]:checked + label::after {
  background-color: #5cb85c;
}
.form-check-label {
  cursor: pointer;
  font-weight: 600;
}
.form-control-success,
.form-control-warning,
.form-control-danger {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.5625rem;
  background-size: 1.125rem 1.125rem;
}
.form-feedback {
  margin-top: 0.25rem;
}
.has-success .form-feedback,
.has-success .form-control-label,
.has-success .col-form-label,
.has-success .form-check-label,
.has-success .custom-control {
  color: #5cb85c;
}
.has-success .form-control {
  border-color: #5cb85c;
}
.has-success .form-control:focus {
  border-color: #5cb85c;
  box-shadow: none;
}
.has-success .input-group-addon {
  color: #5cb85c;
  border-color: #5cb85c;
  background-color: #eaf6ea;
}
.has-success .form-control-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%235cb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}
.has-warning .form-feedback,
.has-warning .form-control-label,
.has-warning .col-form-label,
.has-warning .form-check-label,
.has-warning .custom-control {
  color: #f0ad4e;
}
.has-warning .form-control {
  border-color: #f0ad4e;
}
.has-warning .form-control:focus {
  border-color: #f0ad4e;
  box-shadow: none;
}
.has-warning .input-group-addon {
  color: #f0ad4e;
  border-color: #f0ad4e;
  background-color: white;
}
.has-warning .form-control-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23f0ad4e' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");
}
.has-danger .form-feedback,
.has-danger .form-control-label,
.has-danger .col-form-label,
.has-danger .form-check-label,
.has-danger .custom-control {
  color: #d9534f;
}
.has-danger .form-control {
  border-color: #d9534f;
}
.has-danger .form-control:focus {
  border-color: #d9534f;
  box-shadow: none;
}
.has-danger .input-group-addon {
  color: #d9534f;
  border-color: #d9534f;
  background-color: #fdf7f7;
}
.has-danger .form-control-danger {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23d9534f' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");
}
/*** Label, Badges, Alearts page
==============================================================================*/
/*--- Labels ---*/

.label-pill {
  border-radius: 5em;
}
.label-default-outline {
  color: #777777;
  background-color: transparent;
  border: 2px solid #53d4fa;
}
.label-default {
  color: white;
  background-color: #ced0d2;
  border: 2px solid #ced0d2;
}
.label-primary-outline {
  color: #3a95e4;
  background-color: transparent;
  border: 2px solid #3a95e4;
}
.label-primary {
  color: white;
  background-color: #3a95e4;
  border: 2px solid #3a95e4;
}
.label-success-outline {
  color: #4ab711;
  background-color: transparent;
  border: 2px solid #50ab20;
}
.label-success {
  color: white;
  background-color: #50ab20;
  border: 2px solid #50ab20;
  font-weight: 800;
  font-size: 11px;
  padding: 1px 6px 3px 7px;
  border-radius: 6px;
}
.label-info-outline {
  color: #53d4fa;
  background-color: transparent;
  border: 2px solid #53d4fa;
}
.label-info {
  color: white;
  background-color: #53d4fa;
  border: 2px solid #53d4fa;
  font-weight: 800;
  font-size: 11px;
  padding: 1px 6px 3px 7px;
  border-radius: 6px;
}
.label-warning-outline {
  color: #ffc751;
  background-color: transparent;
  border: 2px solid #ffc751;
}
.label-warning {
  color: white;
  background-color: #ffc751;
  border: 2px solid #ffc751;
  font-weight: 800;
  font-size: 11px;
  padding: 1px 6px 3px 7px;
  border-radius: 6px;
}
.label-danger-outline {
  color: white;
  background-color: #405671;
  border: 2px solid #405671;
  font-weight: 800;
  font-size: 11px;
  padding: 1px 10px 4px 11px;
  border-radius: 6px;
}
.label-danger {
  color: white;
  background-color: #e5343d;
  border: 2px solid #e5343d;
  font-weight: 800;
  font-size: 11px;
  padding: 1px 6px 3px 7px;
  border-radius: 6px;
}
.label-custom {
  color: white;
  background-color: #405671;
  border: 2px solid #405671;
  font-weight: 800;
  font-size: 11px;
  padding: 1px 4px 4px 4px;
  border-radius: 6px;
}
/*--- Badges ---*/

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  color: white;
  background-color: #3a95e4;
}
.nav-pills > li > a:hover {
  color: #3a95e4;
  background-color: transparent;
}
.nav-pills > li > a {
  border-radius: 5px;
  padding: 10px;
  color: #3a95e4;
  font-weight: 600;
  font-size: 14px;
}
.badge-inner {
  margin-bottom: 15px;
}
.badge-inner a {
  color: #3a95e4;
  font-weight: 600;
  font-size: 14px;
}
.badge {
  color: white;
  background-color: #405671;
  font-size: 10px;
  border-radius: 5px;
  padding: 6px 7px;
}
.badge-massege {
  margin-left: 0px;
}
.active .badge {
  color: #9875ab !important;
  background: #fff;
  padding: 4px;
}
/*--- Alerts ---*/

.alert {
  border: 2px solid transparent;
  border-radius: 5px;
}
.custom_alert_lebel span {
  font-size: 12px;
  padding: 3px 4px 2px 3px;
}
.alert-success {
  color: white;
  background-color: #50ab20;
  border-color: #50ab20;
}
.alert-success .close {
  color: #50ab20;
}
.alert-info {
  color: white;
  background-color: #53d4fa;
  border-color: #00b1e6;
}
.alert-info .close {
  color: #00b1e6;
}
.alert-warning {
  color: white;
  background-color: #ffc751;
  border-color: #efa200;
}
.custom_alerts span {
  font-size: 20px;
  color: #ddd;
  margin-right: 11px;
}
.custom_alerts p {
  font-size: 13px;
}
.alert-danger {
  color: white;
  background-color: #e5343d;
  border-color: #bd000a;
}
.alert-danger .close {
  color: #bd000a;
}
.alert-dismissible .close {
  font-size: 16px;
  top: -14px;
  right: -31px;
  text-shadow: none;
  opacity: 1;
}
.alert-dismissible .close:hover {
  opacity: 0.8;
}
.alert-sm {
  font-size: 13px;
  padding: 5px;
}
.alert i {
  margin: 0 10px 0 5px;
}
.alert-sm.alert-dismissible .close {
  top: -5px;
  right: -2px;
}
.dropdown-menu {
  border: none !important;
}
.drop_drop a {
  border-bottom: 1px solid#fff !important;
  background: #e5f3ed !important;
}
.drop_drop a:hover {
  border-bottom: 1px solid#fff !important;
  background: transparent !important;
}
.menues {
  padding: 10px 0px 0px 12px;
}
.menuers {
  padding: 6px 7px 10px 10px;
}
.single_menuers_item h3 {
  font-size: 13px;
  margin: 0 0 4px 0;
  color: #090909;
  word-spacing: 0px;
  font-weight: 600;
}
.custom_drop_scroll {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
.custom_drop_scroll {
  border-radius: 0px 0px 0px 0px;
}
.drop_dropr a {
  border-bottom: 1px solid#fff;
  background: #e5f3ed;
  padding: 0px;
}
.drop_drops a {
  border-bottom: 1px solid#fff;
  background: #e5f3ed;
  padding: 0px;
}
.drop_drops p {
  color: #0e0d0d;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
  font-size: 13px;
  font-weight: 700;
}
.left_items {
  float: left;
  margin-top: 4px;
}
.left_items h3 {
  font-size: 13px;
  margin: 0;
  color: #090909;
  word-spacing: 0px;
  font-weight: 600;
}
.right_items span {
  display: inline;
  padding: 1px 6px 0px 6px;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25em;
  margin: 0px 10px;
}
.drop_down .menus a:hover {
  color: #299694;
  text-decoration: none;
  background-color: #f5f5f5;
}
.drop_down .menus a {
  padding: 5px 10px;
  color: #0f0f0f;
  font-weight: 700;
  font-size: 14px;
}
.menus .fa {
  margin: 0px 5px;
}

/*--- Other page component: ---*/
/*** Lockscreen page
==============================================================================*/

.lock-wrapper-page {
  margin: 7.5% auto;
  width: 360px;
  padding: 15px;
}
.lock_screen_text h3 {
  font-size: 26px;
  margin-top: 14px;
  margin-bottom: 8px;
}
.lock_screen_text p {
  font-size: 14px;
}
.lock_screen_text a {
  font-size: 14px;
}
.logo-lock {
  font-size: 50px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
.user-thumb img {
  height: 88px;
  margin: 0px auto;
  width: 88px;
}
.lock-wrapper-page .form-control {
  padding-left: 40px;
  border: 1px solid #405671;
}
.lock-wrapper-page .fa-key {
  left: 15px;
  top: 10px;
  font-size: 15px;
  position: absolute;
  z-index: 99;
}
@media (max-width: 767px) {
  .lock-wrapper-page {
    margin: 15% auto;
    width: 320px;
  }
  .lock-heading a {
    font-size: 35px;
  }
}
/*** Login page
==============================================================================*/

.login-area {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.login-wrapper {
  padding: 10px;
}
.back-link {
  float: left;
  width: 100%;
  margin: 10px;
}
.container-center {
  max-width: 400px;
  margin: 10% auto 0;
  padding: 20px;
}
.container-center.lg {
  max-width: 800px;
}

.view-header .header-icon {
  font-size: 60px;
  color: #405671;
  width: 68px;
  float: left;
  margin-top: -8px;
  line-height: 0;
}
.view-header .header-title {
  margin-left: 68px;
}
.view-header .header-title h3 {
  margin-bottom: 2px;
  font-size: 24px;
  font-weight: 600;
}
.header-title strong {
  font-size: 12px;
}
/*404 page
==============================================================================*/

.middle-box,
.middle-box2 {
  max-width: 768px;
  z-index: 100;
  margin: 0 auto;
  padding: 15px;
  padding-top: 40px;
}

.error-text h3 span {
  font-weight: 400;
}
.error-desc {
  text-align: left;
}
.error-desc p {
  font-family: "Montserrat", sans-serif;
}
.error-desc .navbar-form {
  margin-top: 30px;
}
.error-desc .navbar-form .form-control {
  border: 1px solid #405671;
}
@media (max-width: 767px) {
  .error-text h1 {
    font-size: 115px;
    font-weight: 800;
    margin-bottom: 0;
    text-align: center;
  }
  .error-text h3 {
    font-size: 30px;
    text-align: center;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 25px;
  }
  .error-desc p {
    font-size: 16px;
    text-align: center;
    margin-bottom: 25px;
  }
  .custom_p_version h1 {
    font-size: 28px !important;
  }
}
.custom_p_version h1 {
  font-size: 35px;
}
@media (min-width: 768px) {
  .error-text h1 {
    font-size: 280px;
    font-weight: 800;
    float: left;
  }
  .custom_p_version h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .error-text h3 {
    font-size: 55px;
    text-transform: uppercase;
    text-align: left;
    margin-left: 19px;
    font-weight: 300;
    float: left;
    margin-top: 40px;
  }
  .error-desc p {
    font-size: 18px;
  }
  .arrow {
    position: absolute;
    bottom: 0;
    left: 168px;
  }
  .m-r-90 {
    margin-right: 155px;
  }
  .m-l-90 {
    margin-left: 155px;
  }
  .error-desc .navbar-form {
    padding: 0px;
  }
}
/*--- 505 page ---*/

.error-text2 h1 {
  font-size: 280px;
  font-weight: 800;
}
.error-desc2 p {
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .error-text2 h1 {
    font-size: 115px;
  }
  .error-desc2 p {
    font-size: 14px;
  }
}
/*-- chart widget --*/
/*-- Pie chart widget --*/

.pie-chart-widget .panel-body {
  padding: 0px;
}
.pie-chart-widget .panel-footer {
  background-color: #fff;
  padding: 0px;
  margin-top: 20px;
}
.pie-chart-widget .panel-footer .btn {
  border: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 12px 0px;
  color: #fff;
  background-color: #474751;
}
.pie-chart-widget .panel-footer .btn:hover {
  background-color: #2c2c38;
}
.pie-chart-widget h2 {
  font-weight: 300;
  margin: 3px 0 0 0;
}
.pie-chart-widget .legend li {
  padding: 10px;
  color: #bdbdbd;
  font-size: 13px;
}
.list-block {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border: none;
}
.list-block ul {
  display: table-row;
}
.list-block li {
  display: table-cell;
  margin: 0;
}
/*--- flot chart ---*/

.flotChart {
  display: block;
  height: 300px;
  position: relative;
  color: #545454;
}
.flotChart-demo {
  width: 100%;
  height: 100%;
  color: #545454;
}
/*--- Sparklines chart ---*/

.spark_about {
  margin: 10px 0 40px;
}
.spark_about p {
  font-size: 14px;
}
.statistic-box > h2 > span.count-number {
  color: #222;
}
.statistic-box > h2 {
  margin-top: 0px;
  margin-bottom: 0px;
}
/*** Calender page
==============================================================================*/

#calendar {
  max-width: 900px;
  margin: 0 auto;
}
#external-events h4 {
  margin-top: 0;
}
#external-events .fc-event {
  margin: 10px 0;
  cursor: pointer;
}
#external-events p {
  margin: 1.5em 0 0;
  font-size: 13px;
}
#external-events p input {
  margin: 0;
  vertical-align: middle;
}
#external-events p label {
  font-weight: 600;
}
/*-- Monthly calender --*/
.monthly_calender {
  width: 100%;
  max-width: 600px;
  display: inline-block;
  /* height: 354px; */
}
/*--- dropzone ---*/

.dropzone {
  min-height: 200px;
  border: 2px dashed #405671;
}
.dropzone .dz-message {
  margin: 4em 0;
}
/* Icon pages
==============================================================================*/
/* The icons */

.icon-body {
  background: #f4f9f3;
}
.cbp_tmtimeline > li i {
  width: 50px;
  height: 50px;
  speak: none;
  text-transform: none;
  font-size: 24px;
  line-height: 46px;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  color: #374767;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  left: 19.4%;
  top: 0;
  margin: 0 0 0 -25px;
  border: 2px solid #e1e6ef;
}
@media screen and (max-width: 65.375em) {
  .cbp_tmtimeline > li .cbp_tmtime span:last-child {
    font-size: 25px;
  }
}
@media screen and (max-width: 47.2em) {
  .cbp_tmtimeline:before {
    display: none;
  }
  .cbp_tmtimeline > li .cbp_tmtime {
    width: 100%;
    position: relative;
    padding: 0 0 20px 0;
  }
  .cbp_tmtimeline > li .cbp_tmtime span {
    text-align: left;
  }
  .cbp_tmtimeline > li .cbp_tmlabel {
    margin: 0 0 30px 0 !important;
    padding: 1em;
    font-weight: 400;
    font-size: 95%;
  }
  .cbp_tmtimeline > li .cbp_tmlabel:after {
    right: auto;
    left: 20px;
    border-right-color: transparent;
    border-bottom-color: #fff;
    top: -20px;
  }
  .cbp_tmtimeline > li i {
    position: relative;
    float: right;
    left: auto;
    margin: -55px 0px 0 0px;
  }
}

.icon_box {
  text-align: center;
  min-height: 92px;
  margin: 10px 0;
  font-size: 42px;
}
.icon_box i {
  font-size: 35px;
  display: block;
  color: #374767;
}
.icon_box:hover i {
  color: #676a6c;
}
.icon-name {
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin-top: 5px;
}
/*pe icon*/

[class^="pe-7s-"],
[class*=" pe-7s-"] {
  display: inline-block;
  font-family: "Pe-icon-7-stroke";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}
/* Buzz Out */

@-webkit-keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
@keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
.hvr-buzz-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
}
.icon_box:hover .hvr-buzz-out,
.hvr-buzz-out:focus,
.hvr-buzz-out:active {
  -webkit-animation-name: hvr-buzz-out;
  animation-name: hvr-buzz-out;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.custom-title_text p {
  font-size: 14px;
}
.custom-title_text h4 {
  font-size: 18px;
  margin-bottom: 10px;
}
.custom-title_text span {
  font-size: 20px !important;
}
.custom-title_text .fa {
  font-size: 20px !important;
}
.custom-title_text2 .fa {
  font-size: 14px !important;
}
.button_hover_back .btn-default:hover {
  background: #ddd;
}

@media (min-width: 1200px) {
  .icon_box_width {
    width: 12.5%;
  }
  .error-desc p {
    font-size: 16px;
  }
}
/*** Flag icon pages
==============================================================================*/

.flag-icon-inner,
.weather-icon-inner,
.material-icon-inner {
  overflow: hidden;
}
.weather_text p {
  font-size: 14px;
}
.pe_icon_text p {
  font-size: 14px;
}
.line_icon_text p {
  font-size: 14px;
}
.material-icon-inner h4 {
  font-size: 16px;
  padding-bottom: 10px;
}
.icon_list {
  list-style: none;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}
.icon_list li {
  float: left;
  width: 33.33%;
  height: 110px;
  padding: 16px 10px;
  font-size: 10px;
  line-height: 1.4;
  text-align: center;
  background-color: #f1f3f6;
  border: 1px solid #fff;
}
.icon_list li:hover {
  color: #fff;
  background-color: #405671;
}
.icon_list li i {
  font-size: 28px;
  display: block;
  margin: 0 auto 10px;
  color: #374767;
}
.icon_list li:hover i {
  color: #fff;
}
.icon_name {
  display: block;
  text-align: center;
  word-wrap: break-word;
}
@media (min-width: 768px) {
  .flag-icon {
    margin-right: 0;
    margin-left: 0;
  }
  .icon_list li {
    width: 20%;
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .inbox_sms img {
    display: none;
  }
}
@media (max-width: 991px) {
  .toolbar {
    padding: 0 0 5px 5px;
    margin: 0;
    background: #f9f9f9 !important;
  }
}
@media (min-width: 1200px) {
  .icon_list li {
    width: 10%;
    font-size: 12px;
  }
}
/*** Themify icons pages
==============================================================================*/

.icon-section {
  margin: 0 0 3em;
  clear: both;
  overflow: hidden;
}
.icon-container {
  width: 25%;
  padding: 0.7em 0;
  float: left;
  position: relative;
  text-align: left;
}
.icon-container [class^="ti-"],
.icon-container [class*=" ti-"] {
  color: #374767;
  position: absolute;
  margin-top: 3px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 16px;
}
.icon-container:hover [class^="ti-"],
.icon-container:hover [class*=" ti-"] {
  font-size: 2.2em;
  margin-top: -5px;
}
.icon-container:hover .icons-name {
  color: #000;
}
.icons-name {
  color: #7a7a7a;
  margin-left: 35px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 13px;
}
.icon-container:hover .icons-name {
  margin-left: 45px;
}
@media (max-width: 767px) {
  .icon-container {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .icon-container {
    width: 50%;
  }
}
/*** Social icon pages
==============================================================================*/

.glyphs.character-mapping {
  margin: 0 0 20px 0;
  padding: 20px 0 20px 30px;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid #e1e6ef;
  border-radius: 4px;
}
.glyphs.character-mapping li {
  margin: 0 30px 20px 0;
  display: inline-block;
  width: 90px;
}
.glyphs.character-mapping .icon {
  margin: 10px 0 10px 15px;
  padding: 15px;
  position: relative;
  width: 55px;
  height: 55px;
  color: #374767;
  overflow: hidden;
  border-radius: 3px;
  font-size: 32px;
}
.glyphs.character-mapping .icon svg {
  fill: #000;
}
.glyphs.character-mapping input {
  margin: 0;
  padding: 5px 0;
  line-height: 12px;
  font-size: 12px;
  display: block;
  width: 100%;
  border: 1px solid #d8e0e5;
  border-radius: 5px;
  text-align: center;
  outline: 0;
}
.glyphs.character-mapping input:focus {
  border: 1px solid #405671;
}
.glyphs.character-mapping input:hover {
  border: 1px solid #405671;
}
.glyphs.css-mapping {
  margin: 0 0 30px 0;
  padding: 30px 0 20px 30px;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid #e1e6ef;
  border-radius: 4px;
}
.glyphs.css-mapping li {
  margin: 0 30px 20px 0;
  padding: 0;
  display: inline-block;
  overflow: hidden;
}
.glyphs.css-mapping .icon {
  margin: 0;
  margin-right: 10px;
  padding: 13px;
  height: 50px;
  width: 50px;
  color: #374767;
  overflow: hidden;
  float: left;
  font-size: 24px;
}
.glyphs.css-mapping input {
  margin: 0;
  margin-top: 5px;
  padding: 8px;
  line-height: 14px;
  font-size: 14px;
  display: block;
  width: 150px;
  height: 40px;
  border: 1px solid #d8e0e5;
  border-radius: 5px;
  background: #fff;
  outline: 0;
  float: right;
}
.glyphs.css-mapping input:focus {
  border: 1px solid #405671;
}
.glyphs.css-mapping input:hover {
  border: 1px solid #405671;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .glyphs.css-mapping li {
    margin: 0 15px 20px 0;
  }
  .glyphs.character-mapping li {
    margin: 0 20px 20px 0;
  }
}
/*--- material-icon-inner ---*/

.material-icon-inner {
  margin-bottom: 20px;
}
.material-icon-inner:last-child {
  margin-bottom: 0;
}
/*** Mailbox page
==============================================================================*/

.mailbox {
  background-color: #fff;
  border-radius: 10px;
  margin: 30px 0 20px;
  overflow: hidden;
  border: 1px solid #e1e6ef;
}
.mailbox-content {
  border-left: 1px solid#ddd;
}
.perfect {
  background: #ddd;
}
.item_perfect a {
  padding: 10px;
  font-size: 14px;
}
.bg1-green {
  background: #50ab20;
  color: #fff;
  padding: 2px;
  font-weight: 600;
  border-radius: 5px;
}
.inbox_sms {
  font-size: 14px;
}
.margin_group {
  margin: 0px 5px;
}
.item_perfect .fa {
  margin: 0px 5px;
}
.profile-usermenu h6 {
  font-size: 13px;
  font-weight: 600;
}
.item_perfect a:hover {
  background: #405671;
  color: #fff;
}
.mailbox-header {
  padding: 0 25px;
  border-bottom: 1px solid #e1e6ef;
}
.inbox-toolbar {
  padding-top: 16.5px;
  float: right;
}
.mailbox .btn {
  border-radius: 25px;
  border-width: 2px;
  padding: 4px 15px;
}
.mailbox .btn:hover {
  border-width: 2px;
}
.mailbox .btn-default {
  color: #89949b;
  border-color: #efefef;
  background-color: #fff;
}
.mailbox .btn-default:hover {
  color: #fff;
  border-color: #62d0f1;
  background-color: #62d0f1;
}
.mailbox-body .row {
  width: 100%;
  display: table;
  table-layout: fixed;
}
.mailbox-body .inbox-nav,
.mailbox-body .inbox-mail {
  display: table-cell;
  vertical-align: top;
  float: none;
}
.padding_bottom_from {
  margin-top: 20px !important;
}
.inbox-nav {
  border-right: 1px solid #e4e5e7;
}
.mailbox-sideber {
  margin-top: 20px;
}
.profile-usermenu ul {
  margin-bottom: 20px;
}
.profile-usermenu ul li a {
  color: #0a0a0b;
  font-size: 13px;
  font-weight: 400;
}
.profile-usermenu ul li a i {
  margin-right: 8px;
  font-size: 14px;
}
.profile-usermenu ul li a:hover {
  background-color: #405671;
  color: #fff;
}
.profile-usermenu ul li.active {
  border-bottom: none;
}
.profile-usermenu ul li.active a {
  color: #405671;
  background-color: #0843684d;
  border-left: 2px solid #405671;
  margin-left: -2px;
}
.profile-usermenu h6 {
  margin: 0 15px 10px;
  border-bottom: 1px solid #e4e5e7;
  padding-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}
.inbox_item {
  color: inherit;
  display: block;
  padding-bottom: 0;
  padding-left: 25px;
  padding-right: 25px;
  border-bottom: 1px solid #e4e5e7;
  background: #f9f9f9;
}
.unread {
  background: white;
}
.inbox_item:hover,
.inbox_item:focus {
  color: inherit;
  background: #08436833;
}
.inbox_item:last-child {
  border-bottom: none;
}
.inbox-avatar {
  padding-top: 12.5px;
  padding-bottom: 12.5px;
}
.inbox-avatar img {
  padding: 2px;
  border-radius: 100px;
  border: 2px solid #eee;
  height: 40px;
  width: 40px;
}
.inbox-avatar-text {
  text-align: left;
  display: inline-block;
  vertical-align: middle;
  color: #93a3b5;
}
.avatar-name {
  color: #43525a;
  font-weight: 600;
}
.badge.avatar-text {
  margin-right: 5px;
  display: inline;
  color: #fff;
  font-size: 72%;
  padding: 3px 10px;
  border-radius: 10px;
}
.inbox-date {
  float: right;
  color: #cdccc8;
  text-align: right;
}
.inbox-date .date {
  position: relative;
  top: 5px;
}
@media (max-width: 767px) {
  .mailbox .btn {
    margin-bottom: 10px;
  }
  .inbox_sms img {
    display: none;
  }
}
@media (min-width: 1200px) {
  .inbox-avatar-text {
    padding-left: 12.5px;
  }
}
/* responsive */
@media (min-width: 320px) and (max-width: 575px) {
  .lobicard > .card-header .dropdown .dropdown-menu {
    left: auto;
    left: 0;
    min-width: 142px;
    margin-top: 5px;
    border-radius: 0;
    font-size: 13px;
    border: 1px solid#000;
    box-shadow: 0px 0px 10px 0px #ddd;
  }
  .mailbox-sideber {
    display: none;
  }
  .inbox_name {
    display: none;
  }
  .custom_delete_mes {
    display: none;
  }
  .none_none {
    display: none;
  }
  .note-popover .popover-content,
  .panel-heading.note-toolbar {
    padding: 0 0 5px 5px;
    margin: 0;
    background: #f9f9f9;
  }
  .drop_drop a {
    border-bottom: 1px solid#fff !important;
    background: #e5f3ed !important;
  }
  .custom_pills a {
    margin-top: 2px;
    font-weight: 600;
    font-size: 10px !important;
    padding: 8px 0px 9px 4px;
  }
  .custom_pills3 .nav-pills .nav-link,
  .nav-pills .show > .nav-link {
    padding: 13px 4px 14px 4px !important;
    font-size: 11px !important;
  }
  .sideways a {
    margin-left: -44px !important;
  }
  .custom_pills5 .nav-pills .nav-link,
  .nav-pills .show > .nav-link {
    font-size: 12px !important;
    padding: 0px 9px 18px 11px !important;
    margin-top: -3px !important;
  }
  .navbar-nav:hover > li > a > i {
    padding: 8px 0px;
    width: 21px;
    height: 44px;
    font-size: 29px;
  }
  .navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
    position: absolute;
    right: 3%;
    left: auto;
    border: 1px solid #ddd;
    background: #fff;
    top: 62px;
  }
  .main-header .navbar-custom-menu {
    margin-top: -14px;
  }
  .navbar-nav > li > a > i {
    padding: 8px 0px;
    width: 21px;
    height: 44px;
    font-size: 29px;
    margin: 0px 3px;
  }
  .navbar-custom-menu .navbar-nav > li > a {
    margin-top: 12px;
    padding-bottom: 2px;
    color: #fff;
  }
}
/*@media(min-width:768px) and (max-width:1199px){

    }*/
/*-- Mailbox details ---*/

.inbox-mail-details {
  line-height: 1.78571;
}
/*** Table
==============================================================================*/

td {
  color: #222;
  font-weight: 500;
}
/*--- Component: Table ---*/

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-top: 1px solid #e4e5e7;
  font-size: 14px;
  padding: 9px 5px 10px 8px;
}
.info1 {
  background: #e8f1f3;
}
.dropdown-menu.note-check.dropdown-fontname {
  font-size: 14px;
  width: 154px;
  box-shadow: 0px 0px 10px 0px #ddd;
}
.padding_from {
  margin-bottom: 20px;
}
.table > thead > tr > th,
.table > tfoot > tr > th {
  border-bottom: 0px;
  border-top: 1px solid #fff;
  font-size: 14px;
}
/* .table-bordered {
  border: 1px solid #e4e5e7;
} */
.border_bottom {
  border-bottom: none;
}

.back_table_color {
  background: #3a526c;
  color: white;
}
.table.no-border,
.table.no-border td,
.table.no-border th {
  border: 0;
}
.exp-drop.show {
  display: inline-block;
  width: 220px;
  border: 1px solid #ddd !important;
  font-size: 13px;
}
.dropdown-menu.exp-drop li {
  padding: 5px 0px 5px 0px;
}
.exp-drop {
  margin-top: 6px;
}
/* text-center in tables */

table.text-center,
table.text-center td,
table.text-center th {
  text-align: center;
}
.table.align th {
  text-align: left;
}
.table.align td {
  text-align: right;
}
.footable-odd {
  background-color: #f9f9f9;
}
/*** Form page
==============================================================================*/

.input-group-text {
  padding: 4px 27px 4px 16px;
  width: 79%;
}
.form-group {
  margin-bottom: 12px;
}
.fontalin {
  text-align: center;
}
.bd-content > ol li,
.bd-content > ul li {
  margin-bottom: 0.25rem;
}
.bd-content h3 {
  margin-top: 0;
}
/* .form-control {
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #e4e5e7;
} */
.green_btn {
  background: #0170ac;
  color: #fff;
  font-size: 14px;
}
.green_btn:hover {
  color: #fff;
}
.timing {
  font-size: 18px;
}
.timing_padding h4 {
  padding: 4px;
  font-size: 19px;
}
/* .form-control:focus {
  border-color: #405671;
  box-shadow: none;
} */
.note-editor.note-frame {
  border: 1px solid #e4e5e7;
}
.icon-list,
.icon-th-list,
.icon-indent-right,
.icon-indent-left,
.icon-share,
.icon-picture {
  display: inline-block;
  width: 14px;
  height: 14px;
  line-height: 14px;
  vertical-align: text-top;
  /* background-image: url(../img/glyphicons-halflings.png); */
  background-position: 14px 14px;
  background-repeat: no-repeat;
}
.icon-font {
  background-position: -144px -48px;
}
.icon-list {
  background-position: -360px -48px;
}
.icon-th-list {
  background-position: -264px 0;
}
.icon-indent-right {
  background-position: -408px -48px;
}
.icon-indent-left {
  background-position: -384px -48px;
}
.icon-share {
  background-position: -120px -72px;
}
.icon-picture {
  background-position: -456px -48px;
}
.wysi-btn {
  margin-bottom: 0;
  font-size: 13px;
  color: #333333;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  background-image: -webkit-linear-gradient(
    0 0,
    0 100%,
    from(#ffffff),
    to(#e6e6e6)
  );
  background-image: -webkit-linear-gradient(to top, #ffffff, #e6e6e6);
  background-image: linear-gradient(to top, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border: 1px solid #cccccc;
  border-bottom-color: #b3b3b3;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
}
/*** Form wizard
==============================================================================*/

.f1-steps {
  overflow: hidden;
  position: relative;
  margin-top: 20px;
}
.f1-progress {
  position: absolute;
  top: 24px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #ddd;
}
.f1-progress-line {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  background: #405671;
}
.f1-step {
  position: relative;
  float: left;
  width: 33.333333%;
  padding: 0 5px;
}
.f1-step-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-top: 4px;
  background: #ddd;
  font-size: 16px;
  color: #fff;
  line-height: 40px;
  border-radius: 50%;
}
.f1-step.activated .f1-step-icon {
  background: #fff;
  border: 1px solid #405671;
  color: #405671;
  line-height: 38px;
}
.f1-step.active .f1-step-icon {
  width: 48px;
  height: 48px;
  margin-top: 0;
  background: #405671;
  font-size: 22px;
  line-height: 48px;
}
.f1-step p {
  color: #ccc;
}
.f1-step.active p,
.f1-step.activated p {
  color: #50ab20;
}
.f1 fieldset {
  display: none;
  text-align: left;
}
.f1-buttons {
  text-align: right;
}
.f1 .input-error {
  border-color: #f35b3f;
}
@media (max-width: 767px) {
  .navbar {
    padding-top: 0;
  }
  .navbar.navbar-no-bg {
    background: #333;
    background: rgba(51, 51, 51, 0.9);
  }
  .navbar-brand {
    height: 60px;
    margin-left: 15px;
  }
  .navbar-collapse {
    border: 0;
  }
  .navbar-toggle {
    margin-top: 12px;
  }
  .top-content {
    padding: 40px 0 110px 0;
  }
}
@media (max-width: 415px) {
  .f1 {
    padding-bottom: 20px;
  }
  .f1-buttons button {
    margin-bottom: 5px;
  }
}
/* preloader */

#preloader {
  /* position: fixed; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ecf0f1;
  z-index: 9999;
  height: 100%;
  width: 100%;
}
#status {
  /* width: 920px; */
  height: 532px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -266px 0 0 -460px;
  /* background: url('../img/preload.gif') center no-repeat; */
}

/*--- back to top ---*/

#toTop {
  position: fixed;
  bottom: 10px;
  right: 15px;
  cursor: pointer;
  display: none;
  z-index: 10;
}
.back-top {
  background-color: #405671;
  border: 1px solid #405671;
  padding: 7px 9px;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  color: #fff;
}
.back-top:hover {
  color: #fff;
  background-color: #222;
  border: 1px solid #222;
}
@media (min-width: 768px) {
  #toTop {
    right: 30px;
  }
}

/*** Portfolio page
==============================================================================*/

.btn-rating {
  background: #405671;
  color: #fbfbfb;
  border: 1px solid #1b366c00;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.btn-rating:hover {
  background: #2a3f54;
  color: #fff;
  border: 1px solid #2a3f54;
}
.btn-exp {
  background: #405671;
  color: #fbfbfb;
  border: 1px solid #405671;
  margin-bottom: 5px;
  font-size: 12px;
  height: 27px;
  width: 133px;
}
.btn:focus {
  box-shadow: none;
  outline: 0;
}

.green_btn2 {
  background: #405671;
  color: #fbfbfb !important;
  border: 1px solid #405671;
  height: 35px;
  line-height: 0px;
  font-size: 14px;
  margin-top: -1px;
  border-radius: 0px 0px 0px 0px;
}
.lobicard-custom-control
  .card-header
  .dropdown
  .dropdown-menu
  > li:nth-child(1) {
  display: none !important;
}
.lobicard-custom-control
  .card-header
  .dropdown
  .dropdown-menu
  > li:nth-child(3) {
  display: none !important;
}
.lobicard-custom-control
  .card-header
  .dropdown
  .dropdown-menu
  > li:nth-child(6) {
  display: none !important;
}
.custom_colos {
  background: #405671;
  border: 1px solid#405671;
}
.custom_colos:hover {
  background: #405671;
  border: 1px solid#405671;
}
.btn-add:hover {
  background: #405671;
  color: #fbfbfb;
  border: 1px solid #405671;
}
.btn-add {
  background: #405671;
  color: #fbfbfb;
  border: 1px solid #405671;
  font-size: 14px;
}
/* .card {
  margin-bottom: 20px;
  background-color: #eeeeee;
  border: none !important;
} */
.custom_card_code {
  box-shadow: none;
  background: none;
  border: 1px solid#ddd;
  background: #f5f5f5;
}
.custom_li_size li {
  font-size: 14px;
}
.custom_progress h4 {
  font-size: 18px;
  margin-bottom: 10px;
}
.custom_progress p {
  font-size: 14px;
}

.custom_tabs {
  box-shadow: none;
  background: none;
  border: none;
}
.custom_tabs2 {
  border: 1px solid#000;
  margin-top: 1px;
  background: #fff;
  padding: 15px;
}
.custom_tabs2 strong {
  font-size: 14px;
}
.tab-border {
  border: 1px solid #000;
  margin-top: 1px;
  background: #fff;
  margin-left: -15px;
  padding: 15px;
}
.tab-border1 {
  border: 1px solid #000;
  margin-top: 1px;
  background: #fff;
  padding: 15px;
  margin-left: 17px;
}
.tab-border1 p {
  font-size: 14px;
}
.tab-border p {
  font-size: 14px;
}
.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  color: #000;
  border-radius: 0px 0px 0px 0px;
}
.custom_pills .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #405671;
  border-radius: 5px 0px 0px 5px;
}
.custom_pills3 .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #775b64;
  background-color: #fff;
  border-radius: 0px 5px 5px 0px;
}
.custom_pills3 .nav-pills .nav-link,
.nav-pills .show > .nav-link {
  color: #775b64;
  font-size: 13px;
  padding: 13px 23px 14px 11px;
  margin-top: 1px;
  font-weight: 600;
}
.custom_pills5 .nav-pills .nav-link.active,
.nav-pills . > .nav-link {
  color: #775b64;
  background-color: #fff;
  border-radius: 5px 5px 0px 0px;
}
.custom_pills5 .nav-pills .nav-link,
.nav-pills .show > .nav-link {
  color: #775b64;
  font-size: 13px;
  padding: 13px 23px 14px 11px;
  margin-top: 1px;
  font-weight: 600;
}
.custom_pills4 .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #405671;
  border-radius: 5px 5px 0px 0px;
}
.custom_pills4 .nav-pills .nav-link,
.nav-pills .show > .nav-link {
  color: #775b64;
  font-size: 13px;
  padding: 3px 23px 14px 13px;
  margin-top: 5px;
  font-weight: 600;
}

.custom_pills2 .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  border-radius: 5px 0px 0px 5px;
}
.custom_pills2 .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #73555e;
  background-color: #fff;
  font-size: 13px;
  padding: 13px 23px 14px 11px;
  margin-top: 1px;
}
.custom_pills2 .nav-pills .nav-link,
.nav-pills .show > .nav-link {
  color: #000;
  font-size: 13px;
  padding: 13px 23px 14px 11px;
  margin-top: 1px;
  font-weight: 600;
}
.custom_pills.active {
  background: #fff;
  background-color: #405671;
}
.tab-borderd {
  margin-left: -17px;
}
.custom_pills a {
  margin-top: 2px;
  font-weight: 600;
  font-size: 13px;
}
.tab-border strong {
  font-size: 14px;
}
.custom_tabs2 p {
  font-size: 14px;
}
.custom_title h4 {
  font-weight: 600;
  font-size: 15px;
  color: #222;
}
.custom_title {
  transition: all 0.5s;
}
.custom_title a:hover h4 {
  color: #5dbab4;
}
.card-header {
  position: relative;
}
.lobicard > .card-header {
  padding: 5px 14px 0px 0px;
  position: relative;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  background: #e8f1f3;
}
.lobicard > .card-header > .card-title {
  float: left;
  max-width: calc(100% - 30px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 30px;
  padding-left: 11px;
  margin-bottom: 9px;
}
.lobicard .card-header .dropdown .dropdown-menu > li > a .card-control-icon {
  top: 0;
  line-height: 30px;
  color: #3f496b;
  padding: 3px;
  font-size: 15px;
}
label {
  max-width: 100%;
  font-weight: 700;
  font-size: 14px;
}
.radio-inline {
  margin: 0px 13px 0px 0px;
}
.form-check {
  padding-left: 0px;
}
.card-header-headshot img {
  height: 60px;
  width: 60px;
  border: 2px solid #405671;
  box-shadow: 1px 3px 3px #3e4142;
}
.custom_profile_head {
  /* background-image: url(../img/carousel-6.jpg); */
  background-size: cover;
  background-position: center;
  border-bottom: 0px solid #fff;
  padding: 40px 20px;
}
.card-content-member {
  background: #d2d6de;
}
.card-content-member h4 {
  font-size: 20px;
  padding-top: 8px;
  margin-bottom: 4px;
}
.card-content-member p {
  font-size: 13px;
  padding-bottom: 10px;
}
.card-content-languages {
  background-color: #fff;
  padding: 15px;
}
.card-content-languages .card-content-languages-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 0.5em;
}
.card-content-languages .card-content-languages-group:last-of-type {
  padding-bottom: 0;
}
.card-content-languages .card-content-languages-group > div:first-of-type {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 5em;
  flex: 0 0 5em;
}
.card-content-languages h4 {
  line-height: 1.5em;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.card-content-languages li {
  display: inline-block;
  padding-right: 0.5em;
  font-size: 0.9em;
  line-height: 1.5em;
}
.card-content-summary {
  background-color: #fff;
  padding: 0px 15px 15px 15px;
}
.card-content-summary p {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}
.card-footer-stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #2c3136;
}
.card-footer-stats div {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 33%;
  flex: 1 0 33%;
  padding: 0.75em;
}
.card-footer-stats div:nth-of-type(2) {
  border-left: 1px solid #3e4142;
  border-right: 1px solid #3e4142;
}
.card-footer-stats p {
  font-size: 0.8em;
  color: #a6a6a6;
  margin-bottom: 0.4em;
  font-weight: 600;
  text-transform: uppercase;
}
.footer p {
  font-size: 11px;
}
.card-footer-stats i {
  color: #ddd;
}
.card-footer-stats span {
  color: #ddd;
  font-size: 15px;
}
.card-footer-stats span.stats-small {
  font-size: 0.9em;
}
.card-footer-message {
  background-color: #405671;
  padding: 15px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.card-footer-message h4 {
  margin: 0;
  text-align: center;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
}
.review-number {
  float: left;
  width: 35px;
  line-height: 1;
}
.review-number div {
  height: 9px;
  margin: 5px 0;
}
.review-progress {
  float: left;
  width: 230px;
}
.review-progress .progress {
  margin: 8px 0;
}
.progress-number {
  margin-left: 10px;
  float: left;
}
.rating-block,
.review-block {
  background-color: #fff;
  border: 1px solid #e1e6ef;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
}
.review-block {
  margin-bottom: 20px;
}
.review-block-img img {
  height: 60px;
  width: 60px;
}
.review-block-name {
  font-size: 12px;
  margin: 10px 0;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.review-block-name a {
  color: #374767;
}
.review-block-date {
  font-size: 12px;
}
.review-block-rate {
  font-size: 13px;
  margin-bottom: 15px;
}
.btn-rating {
  border: 1px solid#ddd;
}
.review-block-title {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
}
.review-block-description {
  font-size: 13px;
}
/*** customer component: custom button
==============================================================================*/
#buttonlist {
  padding-top: 5px;
}
.reset-button {
  margin-top: 10px;
  padding: 0px 0px;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #405671;
  border-color: #405671;
}
.buttonexport {
  padding-bottom: 5px;
}
/* .form-control {
  display: block;
  width: 95%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
} */
/*customer component:Notes*/

.quote-container {
  margin-top: 15px;
  position: relative;
  margin-bottom: 20px;
}
.note {
  color: #333;
  position: relative;
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.3);
}
.note .author {
  display: block;
  margin: 40px 0 0 0;
  text-align: right;
}
.yellow {
  background: #eae672;
  border-left-width: 0px;
  transform: rotate(2deg);
}
.pin {
  background-color: #aaa;
  display: block;
  height: 32px;
  width: 2px;
  position: absolute;
  left: 50%;
  top: -16px;
  z-index: 1;
}
.pin:after {
  background-color: #a31;
  background-image: radial-gradient(
    25% 25%,
    hsla(0, 0%, 100%, 0.3),
    hsla(0, 0%, 0%, 0.3)
  );
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.1),
    inset 3px 3px 3px hsla(0, 0%, 100%, 0.2),
    inset -3px -3px 3px hsla(0, 0%, 0%, 0.2),
    23px 20px 3px hsla(0, 0%, 0%, 0.15);
  content: "";
  height: 12px;
  left: -5px;
  position: absolute;
  top: -10px;
  width: 12px;
}
.pin:before {
  background-color: hsla(0, 0%, 0%, 0.1);
  box-shadow: 0 0 0.25em hsla(0, 0%, 0%, 0.1);
  content: "";
  height: 24px;
  width: 2px;
  left: 0;
  position: absolute;
  top: 8px;
  transform: rotate(57.5deg);
}
@media (max-width: 767px) {
  .note {
    color: #333;
    position: relative;
    width: 150px;
    margin: 0 auto;
    padding: 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.3);
  }
  .pin {
    background-color: #aaa;
    display: block;
    height: 18px;
    width: 2px;
    position: absolute;
    left: 50%;
    top: -16px;
    z-index: 1;
  }
}
/*customer component: buttonlist*/

.btndate {
  margin-bottom: 6px;
}
.border_table {
  border: 1px solid#000;
}
.holiday-month {
  border: 1px solid gray;
}
.border_table a {
  font-weight: 600;
  font-size: 14px;
}
.border_table a:hover {
  background: #ddd;
}
.border_table .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #405671;
}
.full_tab_content {
  border: 1px solid#000;
  padding: 20px;
}
.form_date {
  max-width: 280px;
}
.redate {
  max-width: 97%;
}

/*customer component: custom button*/
@media (max-width: 767px) {
  #sbtn {
    margin-top: 15px;
  }
  .panel .panel-heading .panel-title h4 {
    font-size: 14px;
  }
  .panel-body h4 {
    font-size: 16px;
  }
  .full_tab_content {
    margin-top: 30px;
  }
}
/* .modal-content {
  border-radius: 0;
  max-height: 600px;
  overflow-y: auto;
} */

.imageoverlay {
  background: #3b5168;
  position: absolute;
}

.loginform {
  transform: translate(20%, 50%);
}
demoAfter::after {
  position: relative;
  left: 202px;
  bottom: 176px;
}
/*EnquiryDetails form button css media query*/
/* 
@media (max-width: 768px) {
  .demo-btn.btn.btn-light.btn-sm.float-right {
    position: relative;
    left: 10px;
    bottom: 25px;
  }
}
@media (max-width: 768px) {
  .demo-btn-2.btn.btn-light.btn-sm.float-right {
    position: relative;
    left: 12px;
    bottom: 45px;
  }
}
@media (max-width: 768px) {
  .demo-btn-3.btn.btn-light.btn-sm.float-right {
    position: relative;
    left: 31px;
    bottom: 45px;
  }
}
@media (max-width: 768px) {
  .demo-btn-4.btn.btn-light.btn-sm.float-right {
    position: relative;
    left: 14px;
    bottom: 0px;
  }
} */

/* @media (min-width: 760px) and (max-width: 1004px) {
  .demo-btn.btn.btn-light.btn-sm.float-right {
    position: relative !important;
    left: 10px !important;
    bottom: 24px !important;
  }
}
@media (min-width: 760px) and (max-width: 1000px) {
  .demo-btn-2.btn.btn-light.btn-sm.float-right {
    position: relative;
    left: 10px;
    bottom: 42px;
  }
}
@media (min-width: 1000px) and (max-width: 1024px) {
  .demo-btn-2.btn.btn-light.btn-sm.float-right {
    position: relative;
    left: 6px;
    bottom: 25px;
  }
}
@media (min-width: 1000px) and (max-width: 1024px) {
  .demo-btn-3.btn.btn-light.btn-sm.float-right {
    position: relative;
    left: 10px;
    bottom: 23px;
  }
}
@media (min-width: 760px) and (max-width: 1000px) {
  .demo-btn-3.btn.btn-light.btn-sm.float-right {
    position: relative;
    left: 34px;
    bottom: 42px;
  }
}
@media (min-width: 760px) and (max-width: 1000px) {
  .demo-btn-4.btn.btn-light.btn-sm.float-right {
    position: relative;
    left: 26px;
    bottom: 0px;
  }
} */
