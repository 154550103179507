@import url(color_theme.css);
@import url(./materialdesignicons.css);
.pull-up {
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.pull-up:hover {
  -webkit-transform: translateY(-4px) scale(1.02);
  -moz-transform: translateY(-4px) scale(1.02);
  -ms-transform: translateY(-4px) scale(1.02);
  -o-transform: translateY(-4px) scale(1.02);
  transform: translateY(-4px) scale(1.02);
  -webkit-box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
  box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
  z-index: 999;
}

.box {
  position: relative;
  border-top: 0;
  margin-bottom: 30px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 0px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
  box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
}

.box-body {
  padding: 1.5rem 1.5rem;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  border-radius: 15px;
}
.icon i {
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  margin-right: 30px;
  display: inline-block;
}
.font-size-24 {
  font-size: 1.7142857143rem;
}

.w-60 {
  width: 60px;
}
.h-60 {
  height: 60px !important;
}

.invoice-wrapper {
  position: relative !important;
  background: #eeeeee !important;
}

.hovereff:hover {
  background-color: #f3ebd3 !important;
  color: #666666;
}

.padding0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.borderdown {
  border-bottom: 1px solid #e3e3e3 !important;
}

.filter {
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  border-radius: 3px;

  list-style-type: none;
  margin: 0;
  padding: 0;
}

.filter li {
  padding: 8px;
  cursor: pointer;

  position: relative;
}

.filter ul {
  border: 1px solid rgba(0, 0, 0, 0.3);

  display: none;

  left: 0;
  position: absolute;
  top: 100%;
  background-color: white;
  cursor: pointer;

  list-style-type: none;
  margin: 0;
  padding: 0;

  width: 100px;
}

.filter ul ul {
  left: 100%;
  position: absolute;
  top: 0;
}

.filter li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.filter li:hover > ul {
  display: block;
}

.css-r5rnga {
  margin: 10px 0px 20px 0px !important;
  padding-top: 10px !important;
}

.vh-90 {
  min-height: 90vh !important;
}

.loaderImage {
  margin-top: 20% !important;
  margin-bottom: 20% !important;
}

.fullwid {
  width: 147% !important;
  padding: 0 !important;
}

.minheight350 {
  min-height: 350px !important;
}

.table-white-space {
  border-collapse: separate !important;
  border-spacing: 0 15px !important;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075) !important;
}

.table-hover tbody tr {
  background-color: white !important;
}

.table td,
.table th {
  border-top: none !important;
}

.iconStyle {
  color: #005fa7 !important;
  padding: 7px !important;
  background-color: #f4f4f4 !important;
  font-size: 16px !important;
}

.card_heading {
  font-size: 22px !important;
  color: "#52575D" !important;
  padding-top: 10px;
  padding-left: 15px;
}

.edit_button {
  position: absolute !important;
  right: 61px !important;
  top: 10px !important;
}
.edit_button2 {
  position: absolute !important;
  right: 2% !important;
  top: 10px !important;
}

.delete_button1 {
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
}

.add_button {
  position: absolute !important;
  right: 113px !important;
  top: 10px !important;
}
.delete_button {
  position: absolute !important;
  right: 10px !important;
  bottom: 10px !important;
}
.company_name h1 {
  font-size: 30px !important;
  color: #005fa7 !important;
}

.margin-top-6 {
  margin-top: 6px !important;
}

.canvas {
  background-color: #002;
}

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line if necessary */
  justify-content: center; /* Center aligns the buttons */
  gap: 20px; /* Adds space between buttons */
}

.anmbtn {
  position: relative;
  font-size: 1.3rem !important;
  font-family: "montserrat";
  text-decoration: none;
  margin: 10px; /* Adds margin around buttons */
  border: 1px solid #405671 !important;
  text-transform: uppercase;
  overflow: hidden;
  transition: 1s all ease;
  padding: 10px 20px; /* Adjusts padding for better button size */
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
}

.anmbtn:hover {
  color: white !important;
}

.anmbtn::before {
  background: #405671;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: all 0.6s ease;
}

.btn1::before {
  width: 0%;
  height: 100%;
}

.btn1:hover::before {
  width: 100%;
}

.btn2::before {
  width: 100%;
  height: 0%;
}

.btn2:hover::before {
  height: 100%;
}

.btn3::before {
  width: 100%;
  height: 0%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.btn3:hover::before {
  height: 380%;
}

.btn4::before {
  width: 100%;
  height: 0%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.btn4:hover::before {
  height: 380%;
}

.welcome {
  position: absolute;
  text-align: center;
  width: 100%;
  font-size: 25px;
  font-weight: 600;
}
.welcome span {
  text-decoration: solid;
  text-transform: uppercase;
  color: #405671;
}

.bg-input {
  background-color: #eeeeee !important;
}

.bg-input:hover {
  background-color: white !important;
  outline: none;
}
.bg-input:focus {
  background-color: white !important;
  outline: none;
}

.revisionDiv {
  left: 10%;
  top: 1%;
}

.revisionDiv div {
  left: 15%;
}

.delete-rev-btn {
  top: 2% !important;
  right: 20% !important;
}
@media screen and (max-width: 1100px) {
  .delete-rev-btn {
    top: 2% !important;
    right: 25% !important;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .delete-rev-btn {
    top: 2% !important;
    right: 22% !important;
  }
}
@media (min-width: 1500px) and (max-width: 1950px) {
  .delete-rev-btn {
    top: 1.5% !important;
    right: 19% !important;
  }
  .mk-rev-btn {
    top: 1.5% !important;
  }
}

@media (max-width: 1500px) {
  .anmbtn {
    font-size: 1rem !important;
  }
}

.internet-error {
  height: 60px;
  background: #ff8100;
  margin-top: 0;
  font-size: 20px;
}

.internet-error p {
  font-size: 25px;
  line-height: 60px;
  color: #fff;
  margin: 0;
}
.fill {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2.5px #425873;
}

.fill::before {
  content: "";
  position: absolute;
  -webkit-text-fill-color: #425873;
  transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  text-shadow: 1px 5px 20px #777777bf;
  animation: filling 1.8s 1.5s ease-in-out alternate infinite;
}

@keyframes filling {
  0% {
    content: "I";
  }
  5% {
    content: "In";
  }
  10% {
    content: "Int";
  }
  15% {
    content: "Inte";
  }
  20% {
    content: "Inter";
  }
  25% {
    content: "Intern";
  }
  30% {
    content: "Interne";
  }
  35% {
    content: "Internet";
  }
  40% {
    content: "Internet C";
  }
  45% {
    content: "Internet Co";
  }
  50% {
    content: "Internet Con";
  }
  55% {
    content: "Internet Conn";
  }
  60% {
    content: "Internet Conne";
  }
  65% {
    content: "Internet Connec";
  }
  70% {
    content: "Internet Connect";
  }
  75% {
    content: "Internet Connecti";
  }
  80% {
    content: "Internet Connectio";
  }
  85% {
    content: "Internet Connection";
  }
  90% {
    content: "Internet Connection Lo";
  }
  95% {
    content: "Internet Connection Los";
  }
  97% {
    content: "Internet Connection Lost";
  }
  100% {
    content: "Internet Connection Lost!";
  }
}

.formContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loginForm {
  background: rgba(255, 255, 255, 0.3);
  padding: 3em;
  border-radius: 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.loginForm p {
  font-weight: 500;
  color: #fff;
  opacity: 0.7;
  font-size: 1.4rem;
  margin-top: 15px;
  margin-bottom: 40px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.loginForm a {
  text-decoration: none;
  color: #ddd;
  font-size: 12px;
}

.loginForm a:hover {
  text-shadow: 2px 2px 6px #00000040;
}

.loginForm a:active {
  text-shadow: none;
}
.loginForm img {
  width: 200px;
}
.loginForm input {
  background: transparent;
  width: 235px;
  padding: 1em;
  margin-bottom: 2em;
  border: none;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5000px;
}

.loginForm input:hover {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 4px 4px 60px 8px rgba(0, 0, 0, 0.2);
}

.loginForm input[type="text"]:focus,
.loginForm input[type="password"]:focus {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 4px 4px 60px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid #fff; /* Add a border to the focused input */
}
.loginForm input[type="button"] {
  margin-top: 10px;
  width: 150px;
  font-size: 1rem;
}
.loginForm input[type="button"]:hover {
  cursor: pointer;
}
.loginForm:hover {
  margin: 4px;
}

.loginForm input[type="text"]::-webkit-input-placeholder:focus,
.loginForm input[type="password"]::-webkit-input-placeholder:focus {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  border: 2px solid #fff; /* Add a border to the focused input */
}

.loginForm input::placeholder {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: white;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4); */
}
.loginForm input::-webkit-input-placeholder {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

a,
input:focus,
button:focus,
select:focus {
  outline: none;
}

.drop {
  background: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 10px 10px 60px -8px rgba(0, 0, 0, 0.2);
  position: absolute;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.drop-1 {
  height: 80px;
  width: 80px;
  top: -20px;
  left: -40px;
  z-index: -1;
}
.drop-2 {
  height: 80px;
  width: 80px;
  bottom: -30px;
  right: -10px;
}
.drop-3 {
  height: 100px;
  width: 100px;
  bottom: 120px;
  right: -50px;
  z-index: -1;
}

.drop-4 {
  height: 120px;
  width: 120px;
  top: -60px;
  right: -60px;
}
.drop-5 {
  height: 60px;
  width: 60px;
  bottom: 170px;
  left: 90px;
  z-index: -1;
}
.billingcard {
  height: 120px;
  width: 150%;
  overflow: auto;
  margin-left: -30%;
}
.billingheadingcard {
  height: 100px;
}
.billingcard::-webkit-scrollbar {
  width: 4px;
}
.billingcard::-webkit-scrollbar-track {
  background: #ffffff;
}
.billingcard::-webkit-scrollbar-thumb {
  background: #195fa7;
}

.scrollstyle::-webkit-scrollbar {
  width: 4px;
}
.scrollstyle::-webkit-scrollbar-track {
  background: white;
}
.scrollstyle::-webkit-scrollbar-thumb {
  background: #195fa7;
}

@media (max-width: 1400px) {
  .responsiveCard {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
}

.pt0px {
  padding-top: 0px !important;
}
.CurPointer {
  cursor: pointer;
}

.snav {
  display: block !important;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-list li a {
  text-decoration: none;
  display: block !important;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #515151 !important;
  color: #9d9d9d;
}

.nav-list > li > a {
  color: #c4c4c4;
  font-size: 14px;
  padding-left: 13px !important;
  border-bottom: 1px solid #585858;
}

.nav-list > li > a:hover {
  background-color: #444444;
}

.preview-div {
  position: absolute !important;
  background-color: white;
  top: 0%;
  z-index: 10 !important;
}

.labelWeight {
  font-weight: 600;
}

.inputLabel {
  color: black;
  background-color: #fff;
  /* background-clip: padding-box; */
  border: 1px solid #6591bd;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.hr-theme-slash-2 {
  display: flex;
}
.hr-theme-slash-2 .hr-line1 {
  width: 2.7%;
  position: relative;
  margin: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.hr-theme-slash-2 .hr-line2 {
  width: 77%;
  position: relative;
  margin: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.hr-theme-slash-2 .hr-icon {
  position: relative;
  top: 3px;
  align-items: center;
}

.hr-theme-slash-2 .hr-icon h6 {
  font-weight: bold !important;
}

.hr-theme-slash-2 .hr-icon .labelWeight {
  font-weight: bold !important;
}

.trashBtn {
  position: absolute;
  right: 5%;
}

.categoryBtn:focus,
.categoryBtn:active:focus,
.categoryBtn.active:focus,
.categoryBtn.focus,
.categoryBtn:active.focus,
.categoryBtn.active.focus {
  outline: 0 !important;
  box-shadow: none;
}

.lead_table::-webkit-scrollbar {
  width: 4px;
  height: 7px;
}
.lead_table::-webkit-scrollbar-track {
  background: #ffffff;
}
.lead_table::-webkit-scrollbar-thumb {
  background: #3a526c;
}

.ant-table-body::-webkit-scrollbar {
  width: 4px;
  height: 7px;
}
.ant-table-body::-webkit-scrollbar-track {
  background: #ffffff;
}
.ant-table-body::-webkit-scrollbar-thumb {
  background: #3a526c;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(
    90deg,
    rgba(5, 105, 179, 0.7) 25%,
    transparent 25%,
    transparent 50%,
    rgba(5, 105, 179, 0.7) 50%,
    rgba(5, 105, 179, 0.7) 75%,
    transparent 75%,
    transparent
  );
}

.admin_status {
  position: absolute !important;
  top: 6% !important;
}

.add_files {
  position: absolute;
  top: 2%;
  right: 9.4%;
  z-index: 20;
}
a {
  /* color: #1890ff !important; */
}

.ant-table-thead > tr > th {
  font-weight: bold !important;
}

.ant-btn > span > svg {
  margin-top: -5px;
  font-size: 20px;
}

.files .file-box {
  background-color: #f6f7fb;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  display: inline-block;
  padding: 15px;
  position: relative;
  width: calc(25% - 15px);
}

.files .file-box .file-top {
  align-items: center;
  background-color: #fff;
  border: 1px solid #ececec;
  border-radius: 5px;
  display: flex;
  font-size: 36px;
  height: 100px;
  justify-content: center;
}

.files .ellips {
  opacity: 0.7;
  position: absolute;
  right: 30px;
  top: 30px;
}

.files .file-box + .file-box {
  margin-left: 12px;
}

.fileGoBack {
  top: -5%;
  right: 4%;
}

.total_prof {
  flex-direction: column;
  align-items: flex-end;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}

#tsparticles {
  height: 100vh;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  position: absolute !important;
  top: 50% !important;
  right: 0 !important;
  width: 2px !important; /* Adjusted width for a bolder line */
  height: 1.6em !important;
  background-color: rgba(
    0,
    0,
    0,
    0.1
  ) !important; /* Darkened background color */
  transform: translateY(-50%) !important;
  transition: background-color 0.3s !important;
  content: "" !important;
  z-index: 1 !important;
}

/* char css */

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  width: 100px; /* Adjust size as needed */
}
